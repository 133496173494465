
import React from 'react';
import './index.scss';

const BrandingPage = () => {
    return (
        <div className="page">
            {/* Insert your Database components here */}
        </div>
    );
}

export default BrandingPage;
