import axios from 'axios';
import CacheSingleton from '../lib/Cache';

const URL = process.env.REACT_APP_API_URL;

export const BASE_URL = `${URL}/api`;

const axiosInstance = axios.create({
     baseURL: BASE_URL,
});

// Interceptor to handle request configuration
axiosInstance.interceptors.request.use(config => {
    const { bearerToken, user_id } = handleAuthorizationCalls();

    // Setting headers based on availability of bearerToken and user_id
    config.headers['Content-Type'] = 'application/json';  // Default Content-Type for all requests
    if (bearerToken) {
        config.headers.Authorization = `Bearer ${bearerToken}`;
    }
    if (user_id) {
        config.headers['X-User-ID'] = user_id;
        // Additionally setting a custom 'Payload' header that contains the user_id
        config.headers.Payload = JSON.stringify({ id: user_id });
    }

 
    // Conditionally adding user_id to the body for POST, PUT, PATCH methods
    if ((config.method === 'post' || config.method === 'put' || config.method === 'patch')) {
        if (config.data) {
            // Parse existing data if it's a string or use it directly if it's an object
            let data = typeof config.data === 'string' ? JSON.parse(config.data) : config.data;
            data.user_id = user_id;  // Add user_id
            config.data = JSON.stringify(data);
        } else {
            // If there's no data, create a new object with user_id
            config.data = JSON.stringify({ user_id: user_id });
        }
    }


    return config;
}, error => {
    return Promise.reject(error);
});


axiosInstance.interceptors.response.use(response => {
    return response;
}, error => {
    handleError(error);
    return Promise.reject(error);
});

function handleAuthorizationCalls (){
    /* Get user_id and bearerToken from localStorage */
    const { bearerToken, id } = CacheSingleton.getObject("auth");

    return {
        bearerToken : bearerToken,
        user_id     : id
    }
}

function handleError(e) {
    console.log(e);
    return {
        status: e.response ? e.response.status : 500,
        error: e.response && e.response.data ? e.response.data.error : 'An unknown error occurred'
    };
}

export { axiosInstance };