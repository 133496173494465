import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import APIConnection from '../../../api';
import './index.scss';
import InputText from '../../../components/InputText';
import Button from '../../../components/Button';

const AddonPage = ({ app }) => {
    const location = useLocation();
    const [addOn, setAddon] = useState(null);
    const [envVariables, setEnvVariables] = useState([]);

    useEffect(() => {
        const fetchAddon = async () => {
            if (app && app.id) {
                const searchParams = new URLSearchParams(location.search);
                const name = searchParams.get('name');
                
                if (name) {
                    try {
                        const response = await APIConnection.getAddon({ name, id: app.id });
                        const matchingAddon = app.addons.find(addon => addon.name === name);
                        /* Add fields from matchingAddon */
                        setAddon({...matchingAddon, ...response});
                        // Find the matching addon from app.addons
                        // Merge and filter env variables
                        const responseEnvVariables = response.envVariables || [];
                        const matchingEnvVariables = matchingAddon ? matchingAddon.envVariables : [];
                        
                        const mergedEnvVariables = [
                            ...matchingEnvVariables,
                            ...responseEnvVariables.filter(
                                env => !matchingEnvVariables.some(matchingEnv => matchingEnv.name === env.name)
                            )
                        ];
                        
                        setEnvVariables(mergedEnvVariables);
                    } catch (error) {
                        console.error('Error fetching add-on:', error);
                    }
                }
            }
        };
        fetchAddon();
    }, [app, location]);

    if (!addOn) {
        return <div>Loading...</div>;
    }

    const updateAddon = async () => {
        try {
            await APIConnection.updateAddonEnvVariables({ 
                id: app.id, 
                name: addOn.id, 
                envVariables: envVariables 
            });
            console.log('Add-on updated successfully');
        } catch (error) {
            console.error('Error updating add-on:', error);
        }
    }

    const updateEnvVariable = (name, value) => {
        setEnvVariables(prevVars => 
            prevVars.map(env => 
                env.name === name ? { ...env, value } : env
            )
        );
    }
    return (
        <div className={`addon-page`}>
            <div className="addon-header">
                {addOn.logo ? <img src={addOn.logo} alt={`${addOn.name} logo`} className="addon-logo" /> : <></>}
                <div>
                    <h2>{addOn.name}</h2>
                    <p className='addon-description'>{addOn.description}</p>
                </div>
            </div>
            <div className='status-bar'>
                <div className="status-item">
                    <span className={`status-circle ${addOn.isActive ? 'green' : 'red'}`}></span>
                    <span>Active</span>
                </div>
                <div className="status-item">
                    <span className={`status-circle ${addOn.isFunctioning ? 'green' : 'red'}`}></span>
                    <span>Functioning</span>
                </div>
            </div>
           
            <div className='small-margin'></div>
            {addOn.url ?<a href={addOn.url} target="_blank" rel="noopener noreferrer" className="addon-url">
                Visit {addOn.name}
            </a> : <></>}
            <h4 className=''>Environment Variables</h4>
            <div className='small-margin'></div>
            <div className="env-variables-box box container">
                <ul>
                    {envVariables.map((env, index) => (
                        <li key={index}>
                            <InputText 
                                type={env.name} 
                                onChange={(type, text) => updateEnvVariable(type, text)} 
                                isDisabled={true} 
                                title={env.name} 
                                value={env.value} 
                                placeholder={`${env.value}`} 
                            />
                        </li>
                    ))}
                </ul>
                <div className='fifty small-margin'>
                    <Button onClick={updateAddon} className='button fit'>
                        <h4 className='button-text'>Submit</h4>
                    </Button>
                    <div></div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    app: state.app,
});

export default connect(mapStateToProps)(AddonPage);
