import { SET_DATE } from "../actions/date";


/* Last 30 days */      
export const initialState = {
    start : new Date(new Date().setDate(new Date().getDate() - 30)) ,
    end : new Date()
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_DATE : {     
             return{...action.action}
        }
      default:
        return state;
    }
  }
  




