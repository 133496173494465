import App from './pages/App';
import Home from "./pages/App/Home";
import LandingPage from "./pages/Landing";
import Analytics from "./pages/App/Analytics";  // Ensure this is correctly imported
import Branding from "./pages/App/Branding";
import Code from "./pages/App/Code";
import Database from "./pages/App/Users";
import Email from "./pages/App/Email";
import Settings from "./pages/App/Settings";
import Account from "./pages/App/Account";
import SignUp from "./pages/App/Signup";
import ResetPassword from "./pages/App/ResetPassword";
import TermsAndServices from "./pages/Utils/TermsAndServices";
import PrivacyPolicy from "./pages/Utils/PrivacyPolicy";
import RefundPolicy from "./pages/Utils/Refund Policy";
import AfterPurchase from "./pages/AfterPurchase";
import CreateApp from "./pages/App/CreateApp";
import Financials from "./pages/App/Financials";
import Application from "./pages/App/Application";
import AddonPage from "./pages/App/AddOnPage";

import {
    HomeRounded, Analytics as AnalyticsIcon, Code as CodeIcon, Brush, Contactless, AlternateEmail, Person,
    ViewStream, Settings as SettingsIcon, AccountBoxOutlined, AccountCircleOutlined, MoneyOffCsredOutlined, Money, AppRegistration,
    EmailOutlined,
    ChatBubble,
    Google,
    Facebook,  // Add this import for Facebook icon
    Language,  // Add this import for Language icon (for Weglot)
    Security,  // Add this import for Security icon (for reCAPTCHA)
    Payments,  // Add this import for Payments icon (for Stripe)
    Storage,    // Add this import for Storage icon (for Cloudinary)
    StorageOutlined,
    ShieldOutlined
} from '@mui/icons-material';

export const ROUTES = [
    {
        path: "", key: 'landing', breadcrumb: "Landing", page: <LandingPage />,
    },
    {
        path: "app", key: 'app', breadcrumb: "App", page: <App />,
        children: [
            { active : true, path: "home", key: 'home', breadcrumb: "Home", page: <Home />, icon: <HomeRounded className='icon-leftBar'/> },
            { active : true, path: "application", key: 'application', breadcrumb: "Application", page: <Application />, icon: <AppRegistration className='icon-leftBar'/> },
            { active : true, path: "financials", key: 'financials', breadcrumb: "Financials", page: <Financials />, icon: <Money className='icon-leftBar'/> },
            { active : true, path: "analytics", key: 'analytics', breadcrumb: "Analytics", page: <Analytics />, icon: <AnalyticsIcon className='icon-leftBar'/> },
            { active : false, path: "code", key: 'code', breadcrumb: "Code", page: <Code />, icon: <CodeIcon className='icon-leftBar'/> },
            { active : false, path: "branding", key: 'branding', breadcrumb: "Branding", page: <Branding />, icon: <Brush className='icon-leftBar'/> },
            { active : false, path: "email", key: 'email', breadcrumb: "Email", page: <Email />, icon: <AlternateEmail className='icon-leftBar'/> },
            {/* active : true, path: "socials", key: 'socials', breadcrumb: "Socials", page: <Socials />, icon: <Contactless className='icon-leftBar'/> */},
            { active : true, path: "users", key: 'users', breadcrumb: "Users", page: <Database />, icon: <Person className='icon-leftBar'/> },
            { active : true, path: "account", key: 'account', breadcrumb: "Account", page: <Account /> , icon: <AccountCircleOutlined className='icon-leftBar'/> },
            { active : true, path: "settings", key: 'settings', breadcrumb: "Settings", page: <Settings />, icon: <SettingsIcon className='icon-leftBar'/> },
            {
                path: "add-ons", key: 'add-ons', breadcrumb: "Add-Ons", page: <AddonPage />,
                children: [
                    { active: true, path: "add-ons?name=intercom", key: 'intercom', breadcrumb: "Intercom", page: <AddonPage />, icon: <ChatBubble className='icon-leftBar'/> },
                    { active: true, path: "add-ons?name=google-analytics", key: 'google-analytics', breadcrumb: "Google Analytics", page: <AddonPage />, icon: <Google className='icon-leftBar'/> },
                    { active: true, path: "add-ons?name=facebook-pixel", key: 'facebook-pixel', breadcrumb: "Facebook Pixel", page: <AddonPage />, icon: <Facebook className='icon-leftBar'/> },
                    { active: true, path: "add-ons?name=stripe", key: 'stripe', breadcrumb: "Stripe", page: <AddonPage />, icon: <Payments className='icon-leftBar'/> },
                ]
            }
        ]
    },
    { path: "create-app", key: 'create-app', breadcrumb: "CreateApp", page: <CreateApp />},
    { path: "signup", key: 'signup', breadcrumb: "Signup", page: <SignUp /> },
    { path: "resetPassword", key: 'resetPassword', breadcrumb: "Reset Password", page: <ResetPassword /> },
    { path: "terms-and-services", key: 'termsAndServices', breadcrumb: "Terms and Services", page: <TermsAndServices /> },
    { path: "refund-policy", key: 'refundPolicy', breadcrumb: "Refund Policy", page: <RefundPolicy /> },
    { path: "privacy-policy", key: 'privacyPolicy', breadcrumb: "Privacy Policy", page: <PrivacyPolicy /> },
    { path: "after-purchase", key: 'afterPurchase', breadcrumb: "After Purchase", page: <AfterPurchase /> },

];
