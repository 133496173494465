import React from 'react';
import { FormatColorTextOutlined, Storage, Code, Build, Cloud, NetworkCheck, CheckCircle } from '@mui/icons-material';
import './index.scss';
import { loadingGif } from '../../../config/assets/images';

const StepProcess = ({ currentStep }) => {
    const steps = [
        { icon: FormatColorTextOutlined, label: 'App Info' }, // Step 0: App Info (logo and name)
        { icon: Storage, label: 'Creating Infrastructure' }, // Step 1
        { icon: Code, label: 'Setting Up Repository' }, // Step 2
        { icon: Build, label: 'Building App' }, // Step 3
        { icon: Cloud, label: 'Deploying App' }, // Step 4
        { icon: NetworkCheck, label: 'Configuring Network' }, // Step 5
        { icon: CheckCircle, label: 'Finalizing' } // Step 6
    ];

    return (
        <div className="step-process">
            <div className="icons-container">
                {steps.map((step, index) => (
                    <React.Fragment key={index}>
                        <div className={`step ${index === currentStep ? 'active' : ''} ${index < currentStep ? 'completed' : ''} ${index > currentStep ? 'future' : ''}`}>
                            <div className="circle">
                                <step.icon className="step-icon" />
                            </div>
                            {index === currentStep && <div className="rotating-border"></div>}
                        </div>
                        {index < steps.length - 1 && (
                            <div className={`connector-line ${index < currentStep ? 'completed' : ''} ${index >= currentStep ? 'future' : ''}`}></div>
                        )}
                    </React.Fragment>
                ))}
            </div>
            <div className="current-step-label">
                <img className="small" src={loadingGif} alt='loading'/>
                <span>{steps[currentStep].label}</span>
            </div>
        </div>
    );
};

export default StepProcess;