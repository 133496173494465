import React, { useState, useEffect } from 'react';
import './index.scss';
import { MetaTags } from 'react-meta-tags';
import Stat from '../../../components/Stat';
import { connect } from 'react-redux';
import Graph from '../../../components/Graph';
import Table from '../../../components/Table';
import { formatDate } from '../../../lib/Helpers';
import BubbleGraph from '../../../components/BubbleGraph';
import SkeletonContainer from '../../../components/SkeletonContainer';

const FinancialsPage = ({ app }) => {
    const [data, setData] = useState(null);
    
    useEffect(() => {
        fetchData();
    }, [app.changeId]);
    
    const fetchData = async () => {
        if(app && app.id && app.stripe){
            setData(app.stripe);
            return;
        }
    }
    
    const renderGraph = () => {
        if (!data || !data.analytics || !data.analytics.current) return null;
        
        const dailyRevenue = data.analytics.current.dailyRevenue;
        const dates = Object.keys(dailyRevenue).sort();
        const revenueData = dates.map(date => dailyRevenue[date]);
        
        const today = new Date().toISOString().split('T')[0];
        const estimateStartIndex = dates.findIndex(date => date > today);
        
        const actualRevenue = revenueData.slice(0, estimateStartIndex);
        const estimatedDates = dates.slice(estimateStartIndex);
        
        // Calculate the total actual revenue
        const totalActualRevenue = actualRevenue.reduce((sum, value) => sum + value, 0);
        
        // Calculate the estimated revenue for the remaining days
        const estimatedTotalRevenue = isFinite(data.analytics.current.estimated_revenue) ? data.analytics.current.estimated_revenue : totalActualRevenue;
        const remainingEstimatedRevenue = Math.max(0, estimatedTotalRevenue - totalActualRevenue);
        
        // Distribute the remaining estimated revenue equally among the estimated dates
        const estimatedRevenuePerDay = remainingEstimatedRevenue / estimatedDates.length;
        const estimatedRevenue = estimatedDates.map(() => estimatedRevenuePerDay);

        
        return (
            <Graph 
                title="Revenue Over Time"
                xAxis={dates}
                prefix={'$'}
                yAxis={{
                    label: 'Revenue',
                    data: [
                        { prefix: '$', values: actualRevenue, style: 'solid', label: 'Actual Revenue' },
                        { prefix: '$', values: [...actualRevenue, ...estimatedRevenue], style: 'dashed', label: 'Estimated Revenue' }
                    ],
                }}
                cumulative={true}
            />
        );
    }

    const formatCurrency = (value) => `$${value.toFixed(2)}`;
    const formatPercentage = (value) => `${(value * 100).toFixed(2)}%`;

    const calculateComparison = (current, previous) => {
        if (previous === 0) return current > 0 ? '+100%' : '0%';
        const percentageChange = ((current - previous) / previous) * 100;
        return `${percentageChange > 0 ? '+' : ''}${percentageChange.toFixed(2)}%`;
    }
    
    if(!data) return (
        <div className='page'>
            <SkeletonContainer count={7} type='list'/>
        </div>
    )
    
    return (
        <div className='page'>
            <MetaTags>
                <title>Financials</title>
                <meta name="description" content="Financial overview and statistics" />
                <meta property="og:title" content="Financials" />
            </MetaTags>
            <div className=''>
                <div className='flex'>
                    {data && (
                        <>
                            <Stat
                                title='MRR'
                                value={formatCurrency(data.analytics.totals.mrr)}
                                comparison={calculateComparison(data.analytics.current.revenue, data.analytics.previous.revenue)}
                                tag={'Total'}
                                type='stat'
                            />
                            <Stat
                                title='Active Subscriptions'
                                tag={'Date'}
                                value={data.analytics.totals.active_subscriptions}
                                comparison={calculateComparison(data.analytics.totals.active_subscriptions, data.analytics.previous.active_subscriptions)}
                                isLoading={false}
                                type='stat'
                            />
                            <Stat
                                title='New Subscriptions'
                                tag={'Date'}
                                value={data.analytics.current.new_subscriptions}
                                comparison={calculateComparison(data.analytics.current.new_subscriptions, data.analytics.previous.new_subscriptions)}
                                isLoading={false}
                                type='stat'
                            />
                           <Stat
                                title='Churn Rate'
                                tag={'Date'}
                                value={formatPercentage(data.analytics.totals.churn_percentage)}
                                comparison={calculateComparison(data.analytics.current.churn_in_percentage, data.analytics.previous.churn_in_percentage)}
                                isLoading={false}
                                type='stat'
                            />
                        </>
                    )}
                </div>
                <div className='margin'></div>
                <div className='flex'>
                    {renderGraph()}
                </div>
                <div className='margin'></div>
                <div className='flex'>
                    <div>
                        {/* Last 5 Payments */}
                        <div className='box-container table-last-payments' >
                            <Table title='Last 5 Payments'>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Amount</th>
                                    <th>Type</th>
                                </tr>
                            </thead>
                            {data && data.payments.slice(0, 5).map(payment => (
                                <tr key={payment.id}>
                                    <td>{formatDate(new Date(payment.created*1000))}</td>
                                    <td> <h5>{formatCurrency(payment.amount/100)}</h5></td>
                                    <td> <h5 className='tag-outlined'>{payment.description}</h5></td>
                                </tr>
                                ))}
                            </Table>
                        </div>
                    </div>
                    <BubbleGraph 
                        title="Subscriptions Breakdown"
                        data={[
                            { label: 'Active Subscriptions', value: data.analytics.totals.subscriptions.active },
                            { label: 'Canceled Subscriptions', value: data.analytics.totals.subscriptions.canceled },
                            { label: 'Pending Subscriptions', value: data.analytics.totals.subscriptions.pending }
                        ]}
                    />
                </div>
                {data && data.is_estimate && (
                    <div className="estimate-notice">
                        Note: This is an estimate based on current data.
                    </div>
                )}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    app: state.app
});

export default connect(mapStateToProps)(FinancialsPage);
