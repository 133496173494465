import ReactGA from "react-ga4";
import { GOOGLE_ANALYTICS_MEASUREMENT_ID, IS_PRODUCTION } from "../../config";
import TagManager from 'react-gtm-module'
import { generateRandomString } from "./Helpers";
 
const tagManagerArgs = {
    gtmId: ''
}
 
class GoogleAnalytics{
    constructor(){
        this.initialized = false
    }

    initialize =  async () => {
        if(this.initialized){return}
        await TagManager.initialize(tagManagerArgs)
        await ReactGA.initialize(GOOGLE_ANALYTICS_MEASUREMENT_ID);
        this.initialized = true
    }

    pageviewNoKey = async ({page, title}) => {
        await ReactGA.send({hitType : 'pageview', 
            page : page,
            title : title
        });
    }

    register = async ({id=generateRandomString(5)}) => {
        if(!IS_PRODUCTION){return}
        await this.initialize();
        await ReactGA.event("register", {transaction_id : id});
    }

    subscribe = async ({price=0}) => {
        if(!IS_PRODUCTION){return}
        await this.initialize();
        await ReactGA.event("subscribe", {
            price : price
        });
    }
}


const GoogleAnalyticsSingleton = new GoogleAnalytics();

export default GoogleAnalyticsSingleton;