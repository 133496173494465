import './index.scss';
import { Component } from 'react';
import { connect } from 'react-redux';
import { MetaTags } from 'react-meta-tags';
import GoogleAnalyticsSingleton from '../../lib/GoogleAnalytics';
import UrlNavigatorSingleton from '../../lib/UrlNavigator';
import UserAPI from '../../lib/User';
import { generateRandomString } from '../../lib/Helpers';
import { logo } from '../../../config/assets/images';

class AfterPurchasePage extends Component{
    constructor(props){super(props);this.state = {packs : [], sounds : [], totalSounds : 100, totalPacks : 100, loading : true}}
    
    componentDidMount(){
        let { price } = UrlNavigatorSingleton.getFields(["price"]);
        GoogleAnalyticsSingleton.register({id : generateRandomString(5)});
        GoogleAnalyticsSingleton.subscribe({price : price ? price : 0});
    }

    goToLogin = async () => {
        try{
            UrlNavigatorSingleton.toApp();
            this.setState({isLoading : false})
        }catch(err){
            //Handle Err with UI
            console.log(err);
            this.setState({error : err, isLoading : false})
        }
    }


    __goToApp = async () => {
        this.setState({isLoading : true})
        // Verify if User Exists in Cache
        let isAuth = await UserAPI.auth();
        if(isAuth){
            // Go to Signup Page
            this.setState({isLoading : false})
            UrlNavigatorSingleton.toApp();
        }else{
            this.setState({isLoading : false})
        }
    }

    render = () => {
        const { isLoading } = this.state;
        
        return (
            <>  
                <MetaTags>
                    <title>Congratulations!</title>
                    <meta name="description" content="Congratulations" />
                    <meta property="og:title" content="Enjoy your Purchase!" />
                </MetaTags>
                <div className='thank-you-grid'>
                    <div className='singup-container'>
                        <div className='outside-container'>
                            <div className='inside-container'>
                                <div className='text-container-sign container-outline'>
                                    <div className='signup-container-main'>
                                        <img src={logo} className='logo-signup'/>
                                        <h2 className='text-center'>Congratulations 🎉</h2>
                                        <h4 className='text-center text-grey' style={{marginTop : 10}}>Click the link below to Login via Google or to setup your password!</h4>
                                    </div>
                                    <button 
                                        className={`btn btn-primary full-width`} 
                                        style={{marginTop : 20, opacity : 1}} 
                                        onClick={this.goToLogin}>
                                        <h3>
                                            Go to Login
                                        </h3>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            )
    }
    
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }

  export default connect(mapStateToProps)(AfterPurchasePage);
