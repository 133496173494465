/* Create Component of Stat inside a Box like number of signed users, with comparison with last week or last day with a percentage */
import React from 'react';
import './index.scss';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import SkeletonContainer from '../SkeletonContainer';
import { connect } from 'react-redux';
import { getDisplayText } from '../DatePicker';

const Stat = (props) => {
    let { title, value, comparison, isLoading, type, tag, le } = props;
    
    if(tag === 'Date'){
        tag = getDisplayText({date: props.date});
    }
    
    const renderComparison = () => {
        if (comparison) {
            const isPositive = parseFloat(comparison) > 0;
            return (
                <div className='comparison-container'>
                    <h6 className={`comparison-text ${isPositive ? 'green' : 'red'}`}>{comparison}</h6>
                    <div className='comparison-icon-container'>
                        {isPositive ? (
                        <ArrowUpward className='comparison-icon' style={{ float: 'right' }} />
                    ) : (
                            <ArrowDownward className='comparison-icon' style={{ float: 'right' }} />
                        )}
                    </div>
                </div>
            );
        }
    }
    
    if(isLoading) return (
        <div className='stat-container'>
            <div className='box-container'>
                <SkeletonContainer/>
            </div>
        </div>
    );

    return (
        <div className='stat-container'>
            <div className='box-container'>
                <h5 className='title grey'>{title} {tag && <span className='tag-outlined'>{tag}</span>}</h5>
                <div className='value-container'>
                    {isLoading ? <SkeletonContainer count={1} type={type} /> : <h2 className='value'>{value}</h2>}
                    {renderComparison()}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {    
    const date = state.date;
    return {
        date
    }
}
export default connect(mapStateToProps)(Stat);
