import './index.scss';
import { Component } from 'react';
import { connect } from 'react-redux';
// Import Material-UI icon
import EditIcon from '@mui/icons-material/Edit';

class InputText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFocused: true,
            isDisabled: props.isDisabled || false,
            isEditing: false
        }
    }

    onChange = (e) => {
        const { type } = this.props;
        let __normalizedText = e.target.value;
        if(type == ('email' || 'password')){
            __normalizedText = __normalizedText.trim();
        }
        if(this.props.onChange){
            this.props.onChange(type, __normalizedText);
        }
    }

    handleBlur = () => {
        this.setState({
            isFocused: false,
            isEditing: false,
            isDisabled: this.props.isDisabled || false
        });
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.handleBlur();
        }
    }

    toggleEdit = () => {
        this.setState(prevState => ({
            isDisabled: false,
            isEditing: true
        }));
        
        // Focus the input after a short delay to allow for the animation
        setTimeout(() => {
            this.inputRef.focus();
        }, 300);
    }
    
    render = () => {
        const { isFocused, isDisabled, isEditing } = this.state;
        var { placeholder, title, type, error, isValid = true, isDisabled: propIsDisabled } = this.props;
        isValid = isFocused ? true : isValid;
        const finalDisabled = isDisabled && propIsDisabled && !isEditing;
        
        return (
            <div className='inputTextDiv'>
                <h4 className={`grey margin-bottom ${!isValid ? 'error-text' : ''}`}>{title}</h4>
                <div className={`input-wrapper ${finalDisabled ? 'disabled' : ''} ${isEditing ? 'editing' : ''}`}>
                    <input
                        ref={(input) => { this.inputRef = input; }}
                        className={`${!isValid ? 'error-box' : ''}`}
                        onChange={this.onChange}
                        onBlur={this.handleBlur}
                        onKeyDown={this.handleKeyDown}
                        type={type}
                        placeholder={placeholder}
                        disabled={finalDisabled}
                    />
                    {(isDisabled || propIsDisabled) && !isEditing && (
                        <EditIcon className="edit-icon" onClick={this.toggleEdit} fontSize="small" />
                    )}
                </div>
                {!isValid ? <h5 className='input-error'>{error}</h5> : null}
            </div>
        )
    }
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
}

export default connect(mapStateToProps)(InputText);
