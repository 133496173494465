const GENERAL_INFO = {
    name : 'Sassify',
    description : 'Shopify Reimagined for Software Development',
    url : 'https://sassify.app',
    email : 'contact@sassify.app',
    socials : [
        'https://www.facebook.com/sassifyapp',
        'https://www.instagram.com/sassifyapp',
        'https://www.linkedin.com/company/asymetriclabs',
        'https://www.twitter.com/sassifyapp',
    ],
    theme_color : '#eeeefd',
    background_color : '#0f1400',
}



module.exports = GENERAL_INFO;