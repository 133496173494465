import React, { useEffect, useState } from 'react';
import './index.scss';
import { connect } from 'react-redux';
import { MetaTags } from 'react-meta-tags';
import Stat from '../../../components/Stat';
import { formatNumberWithCommas, formatPercentage, formatPrice } from '../../../lib/Helpers';
import Graph from '../../../components/Graph';
import SkeletonContainer from '../../../components/SkeletonContainer';

const HomePage = ({ user, app }) => {
    const [sessions, setSessions] = useState([]);
    const [stripeData, setStripeData] = useState(null);
    
    useEffect(() => {
        if(app?.sessions && app?.stripe){
            setSessions(app.sessions);
            setStripeData(app.stripe);
        }
    }, [app.changeId]);
    
    if(!sessions || !stripeData) return (
        <div className='page'>
            <SkeletonContainer count={7} type='list'/>
        </div>
    );

    // Extract byDay data
    const byDayData = sessions.withinDateRange.byDay;
    const dates = Object.keys(byDayData).sort();
    const sessionValues = dates.map(date => byDayData[date]);
    
    const renderGraph = () => {
        if (!stripeData || !stripeData.analytics || !stripeData.analytics.current || !sessions || !sessions.withinDateRange) return null;
        
        const dailyRevenue = stripeData.analytics.current.dailyRevenue;
        const sessionByDay = sessions.withinDateRange.byDay;
        
        // Get all unique dates from both datasets
        const allDates = new Set([
            ...Object.keys(dailyRevenue),
            ...sessionByDay.map(entry => entry.day)
        ]);

        // Sort dates
        const sortedDates = Array.from(allDates).sort((a, b) => new Date(a) - new Date(b));

        // Merge data into a single array of objects
        const mergedData = sortedDates.map(date => {
            const revenue = dailyRevenue[date] || 0;
            const sessionEntry = sessionByDay.find(e => e.day === date);
            const visitors = sessionEntry ? sessionEntry.amount : 0;
            return { date, revenue, visitors };
        });
        
        // Extract data for the graph
        const dates = mergedData.map(entry => entry.date);
        const revenueData = mergedData.map(entry => entry.revenue);
        const sessionData = mergedData.map(entry => entry.visitors);
        
        return (
            <Graph 
                title="User Sessions and Revenue"
                xAxis={dates}
                yAxis={{
                    data: [
                        { label: 'Unique Visitors', values: sessionData, style: 'solid' },
                        { prefix: '$', label: 'Revenue', values: revenueData, style: 'solid' }
                    ],
                }}
            />
        );
    }

    return (
        <div className='page'>
            <MetaTags>
                <title>Home</title>
                <meta name="description" content="Stay up to date with today´s trends" />
                <meta property="og:title" content="Home" />
            </MetaTags>
            <div className='flex'>
                <Stat title='MRR' tag={'Total'} value={formatPrice(stripeData.analytics.totals.mrr)} />
                <Stat title='Unique Visitors' tag={'Date'} value={formatNumberWithCommas(sessions.total.uniqueVisitors, 0)} />
                <Stat title='Conversion Rate' tag={'Date'} value={formatPercentage(stripeData.analytics.totals.subscriptions.active / sessions.total.uniqueVisitors)} />
                <Stat title='Money Per Visitor' tag={'Date'} value={formatPrice(stripeData.analytics.totals.revenue / sessions.total.uniqueVisitors)} />
            </div>
            <div className="margin"></div>
            <div className="flex">
                {renderGraph()}
            </div>
            <div className="margin"></div>
        </div>
    );
}

function mapStateToProps(state) {
    const user = state.user;
    const app = state.app;  
    return {
        user,
        app
    };
}

export default connect(mapStateToProps)(HomePage);
