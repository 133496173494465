import { combineReducers } from 'redux';
import user from './user';
import register from './register';
import setFilters from './filters';
import route from './route';
import notification from './notification';
import modal from './modal';
import app from './app';
import date from './date';

const createRootReducer = (history) => combineReducers({
    user,
    register,
    notification,
    date,
    route,
    setFilters,
    modal,
    app
});
 
export default createRootReducer;