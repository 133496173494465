import React, { useCallback } from 'react'
import './index.scss';
import { UploadFileOutlined } from '@mui/icons-material';
import { useDropzone } from 'react-dropzone'

const UploadArea = (props) => {
    const { type, description, currentImage, title } = props;

    const readFileAsDataURL = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => resolve(event.target.result);
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(file);
        });
    };

    const onDrop = useCallback(async (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            try {
                const file = acceptedFiles[0];
                if (file.size > 5 * 1024 * 1024) {
                    throw new Error("File size exceeds 5MB limit");
                }
                const dataUrl = await readFileAsDataURL(file);
                props.set({ type, data: dataUrl });
            } catch (error) {
                console.error("Error processing file:", error);
                props.onError && props.onError(error.message);
            }
        }
    }, [type, props]);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        maxSize: 5 * 1024 * 1024,
        accept: 'image/*',
        multiple: false
    });

    return (
        <div className='input-box'>
            <h4 className='grey'>{title}</h4>
            <div className={`input-area ${currentImage ? 'has-image' : ''}`} {...getRootProps()}>
                <input {...getInputProps()} />
                {currentImage ? (
                    <div className="image-container">
                        <img src={currentImage} alt="Current" className="current-image" />
                    <div className="overlay">
                        <UploadFileOutlined />
                        <p>{description || 'Change Image'}</p>
                    </div>
                </div>
            ) : (
                <div className='middle'>
                        <UploadFileOutlined />
                        <p>{description || 'Drop image here or click to upload'}</p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default React.memo(UploadArea);