import React, { useState, useEffect, useRef } from 'react';
import './index.scss';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

const DeploymentLogs = ({ logs }) => {
    const [logEntries, setLogEntries] = useState([]);
    const [isExpanded, setIsExpanded] = useState(true);
    const logContainerRef = useRef(null);

    useEffect(() => {
        try {
            const parsedLog = JSON.parse(logs);
            const timestamp = new Date().toLocaleTimeString();
            setLogEntries(prevEntries => [
                ...prevEntries.map(entry => ({ ...entry, opacity: 0.5 })),
                { message: parsedLog.message, timestamp, opacity: 1 }
            ]);
        } catch (e) {
            console.error('Invalid log format', e);
        }
    }, [logs]);

    useEffect(() => {
        if (logContainerRef.current && isExpanded) {
            logContainerRef.current.scrollTop = logContainerRef.current.scrollHeight;
        }
    }, [logEntries, isExpanded]);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className={`deployment-logs-container ${isExpanded ? 'expanded' : 'collapsed'}`}>
            <button className="toggle-button" onClick={toggleExpand}>
                {isExpanded ? <ChevronRight className="icon" /> : <ChevronLeft className="icon" />}
            </button>
            <div className="deployment-logs">
                <div className="logs-header">
                    <h3>Logs</h3>
                </div>
                <div className="logs-content" ref={logContainerRef}>
                    {logEntries.map((entry, index) => (
                        <div key={index} style={{ opacity: entry.opacity }}>
                            <span className="timestamp">[{entry.timestamp}]</span>
                            <span className="message">{entry.message}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default DeploymentLogs;