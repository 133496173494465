import React, { useState } from 'react';
import './index.scss';
import { formatNumberWithCommas, formatPrice } from '../../lib/Helpers';
import ToolTip from '../Tooltip'; // Import the ToolTip component
import Stat from '../Stat';

const LinearGraph = ({ data, title, tag }) => {
    const [hoveredSource, setHoveredSource] = useState(null);
    const totalVisitors = data.reduce((sum, source) => sum + source.visitors, 0);
    console.log(data)
    const getDomainFromUrl = (url) => {
        try {
            // Extract domain from URL or use the string directly if it's already a domain
            return url.replace(/^https?:\/\//, '').split('/')[0];
        } catch (error) {
            return url;
        }
    };
    
    const getFaviconUrl = (url) => {
        /* handle if url is '/' */
        if (url === '' || url === '/') {
            /* return world icon */
            return 'https://png.pngtree.com/png-vector/20190429/ourmid/pngtree-web-search-icon-design-png-image_1002841.jpg';
        }
        return `https://www.google.com/s2/favicons?domain=${getDomainFromUrl(url)}&sz=64`;
    }
    
    return (
        <div className="box-container">
            <div className='linear-graph'>
                <Stat title={title} tag={tag}   value={formatNumberWithCommas(totalVisitors, 0)} />
                <div className="small-margin"></div>
                <div className="source-list">
                    {data.sort((a, b) => b.visitors - a.visitors).map((source) => {
                        const percentage = (source.visitors / totalVisitors) * 100;
                        const faviconUrl = getFaviconUrl(source.name);
                        const revenuePerVisitor = (source.revenue / source.visitors).toFixed(2);
                        const conversionRate = ((source.conversions / source.visitors) * 100).toFixed(2);
                        
                        return (
                            <div 
                                key={source.name} 
                                className="source-item-container"
                                onMouseEnter={() => setHoveredSource(source.name)}
                                onMouseLeave={() => setHoveredSource(null)}
                            >
                                <img src={faviconUrl} alt={source.name} className="source-image" />
                                <div className="source-item">
                                    <div className="source-info">
                                        <span className="source-name full-width">{source.name ? source.name : 'Direct/No Source'}</span>
                                        <h6 className="">{formatNumberWithCommas(source.visitors, 0)}</h6>
                                        <span className="percentage">({percentage.toFixed(1)}%)</span>
                                    </div>
                                    <div className="graph-bar-container">
                                        <div className="graph-bar total-bar"></div>
                                        <div 
                                            className="graph-bar source-bar" 
                                            style={{ width: `${percentage}%` }}
                                        ></div>
                                        <div 
                                            className="graph-bar secondary-bar" 
                                            style={{ width: `${percentage / 2}%` }}
                                        ></div>
                                    </div>
                                    {hoveredSource === source.name && (
                                        <ToolTip>
                                            <div className="tooltip-container">
                                                <div className="tooltip-header">
                                                    <img src={faviconUrl} alt={source.name} className="source-icon" />
                                                    <span className="source-name">{source.name}</span>
                                                </div>
                                                <div className="tooltip-content">
                                                    <div className="tooltip-row">
                                                        <span className="color-indicator visitors"></span>
                                                        <span className='grey'>Visitors</span>
                                                        <span>{formatNumberWithCommas(source.visitors, 0)}</span>
                                                    </div>
                                                    <div className="tooltip-row">
                                                        <span className="color-indicator revenue"></span>
                                                        <span className='grey'>Revenue</span>
                                                        <span>{formatPrice(source.revenue)}</span>
                                                    </div>
                                                    <div className="tooltip-row">
                                                        <span className='grey'>Revenue/visitor</span>
                                                        <span>{formatPrice(revenuePerVisitor)}</span>
                                                    </div>
                                                    <div className="tooltip-row">
                                                        <span className='grey'>Conversion rate</span>
                                                        <span>{conversionRate}%</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </ToolTip>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default LinearGraph;

