/* Create Brand Picker Modal */
/* Should be able to select a brand from a list of brands */
/* Should be able to search for a brand */
/* Should be able to create a new brand */

import { connect } from "react-redux";
import Modal from "..";
import "./index.scss";
import { setModal } from "../../../redux/actions/modal";
import { setApp } from "../../../redux/actions/app";
import Button from "../../Button";
import { AddOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { FaCheck, FaClock, FaTimes, FaPencilAlt } from 'react-icons/fa';

const BrandPicker = ({ user, dispatch }) => {
    /* Get navigation props */
    const navigate = useNavigate();

    if(!user || !user.apps) return null;
    
    const setAppInPlatform = (app) => {
        /* Clean the app state */
        dispatch(setApp({id: null}));
        /* Set the app in the platform */
        dispatch(setApp(app));
        /* Close the modal */
        dispatch(setModal(null));
    }

    const renderDomainStatus = (domain) => {
        if (domain.active) {
            return <FaCheck className="status-icon active" title="Active" />;
        } else if (domain.acmStatus === 'pending' || domain.sslStatus === 'pending') {
            return <FaClock className="status-icon pending" title="Pending" />;
        } else {
            return <FaTimes className="status-icon inactive" title="Inactive" />;
        }
    };

    const renderAppList = (app) => {
        return (
            <div className="app-list-item" onClick={() => setAppInPlatform(app)}>
                <img className="app-logo" src={app.branding?.logo} alt={`${app.name} logo`}/>
                <div className="app-info">
                    <h4>{app.fullName}</h4>
                    <span className="domain">{app.hosting.alias_domain || 'No domain'}</span>
                </div>
            </div>
        )
    }

    const renderCreateAppButton = () => {
        return (
            <Button className={'app-list-item new-app'} onClick={createApp}>
                <AddOutlined className="app-logo"/>
                <div className="app-info">
                    <h4>Create App</h4>
                    <span className="domain">Create a new app</span>
                </div>
            </Button>
        )
    }
    
    const createApp = () => {
        navigate('/create-app');
    }

    return (
        <Modal keyItem='BrandPicker' middle={true} className="brand-picker-box">
            <div className="title-container">
                <h4>Your Apps, <h4 className="grey inline">{user.name}</h4></h4>
            </div>
        
            <div className="margin"/>
            <div>
                {user.apps.map(renderAppList)}
                {renderCreateAppButton()}
            </div>
        </Modal>
    )
}

function mapStateToProps(state) {
    return {
        user: state.user
    };
}

export default connect(mapStateToProps)(BrandPicker);
