// Assuming the necessary files and configurations are correctly set up in your project
import React, { useState, useEffect, StrictMode } from 'react';
import { createRoot } from "react-dom/client";
import '../index.scss'; // Ensure your CSS variables are defined here or in an imported file
import reportWebVitals from '../__config/reportWebVitals';
import store from './redux/store'; // Your Redux store
import { createTheme, ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';
import { SkeletonTheme } from 'react-loading-skeleton';
import GoogleAnalyticsSingleton from './lib/GoogleAnalytics'; // Google Analytics setup
import FacebookPixelSingleton from './lib/FacebookPixel'; // Facebook Pixel setup
import { getCSSVariableValue } from './lib/Helpers';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CustomSwitch from './components/CustomSwitch';
import { ROUTES } from './route';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

// Initialize Google Analytics and Facebook Pixel
//GoogleAnalyticsSingleton.initialize();
//FacebookPixelSingleton.initialize();


const RouteIndex = () => {
    return (
        <BrowserRouter>
            <CustomSwitch>
                {ROUTES.map(route => {
                    if (route.children) {
                        return (
                            <Route path={route.path} element={route.page} key={route.key}>
                                {route.children.map(subRoute => (
                                    <Route path={subRoute.path} element={subRoute.page} key={subRoute.key} />
                                ))}
                            </Route>
                        );
                    } else {
                        return <Route path={route.path} element={route.page} key={route.key} />;
                    }
                })}
            </CustomSwitch>
        </BrowserRouter>
    );
}

const App = () => {
  const [muiTheme, setMuiTheme] = useState(createTheme());

  useEffect(() => {
    // Dynamically create the MUI theme
    const dynamicMuiTheme = createTheme({
    background: {
      default: getCSSVariableValue('--primary-color')
    },
    palette: {
      mode: getCSSVariableValue('--color-mode')
    }
    });


    setMuiTheme(dynamicMuiTheme);
  }, []); 

  return (
    <Provider store={store}>
      <SkeletonTheme>
          <ThemeProvider theme={muiTheme}>
            <RouteIndex />
          </ThemeProvider>
      </SkeletonTheme>
    </Provider>
  );
};

root.render(
    <App />
);

reportWebVitals();