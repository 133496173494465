import './index.scss';
import { Component } from 'react';
import { connect } from 'react-redux';
import Loading from '../../components/Loading';
import { MetaTags } from 'react-meta-tags';
import Header from '../../components/Header';
import Button from '../../components/Button';
import Stat from '../../components/Stat';
import Footer from '../../components/Footer';
import { GENERAL_INFO } from '../../../config';

class LandingPage extends Component{
    constructor(props){super(props);this.state = {loading : false}}
    
    componentDidMount(){
    }    
    
    sendMail = () => {
        console.log('sendMail');
        window.open(`mailto:${GENERAL_INFO.email}?subject=I want to get access to ${GENERAL_INFO.name}`, '_blank');
    }
    
    render = () => {
        const { loading } = this.state;
        
        return (
            <>  
                <>{ (loading) ? <Loading/> : null}</>
                <MetaTags>
                    <title> Asymetric </title>
                    <meta name="description" content="Finding Asymetric Risk Opportunities" />
                    <meta property="og:title" content="Asymetrism" />
                </MetaTags>
                {/* Header */}
                <Header showRegister={false}/>
                {/* Main Container */}
                <div className='page'>  
                    <div className=''>
                        <div className='small-margin'></div>
                        <h5 className='grey tag-outlined text-center'>AI Powered</h5>
                        <h1 className='text-center'>Shopify, but for SaaS</h1>
                        <h3 className='grey text-center'>Zero to First Sale in Hours, or Money Back</h3>
                        <div className='small-margin'></div>
                        <div className='margin'></div>
                        <Button className='button fit padded' onClick={this.sendMail}><h4>Get Exclusive Access 👋</h4></Button>
                    </div>
                    <div className='margin'></div>
                    <div className='section'>
                        <h3 className='grey'>Infrastructure</h3>
                        <div className='margin'></div>
                        <div className='flex'>
                            <Stat title='Hosting' value='AWS' tag='Auto-Managed'/>
                            <Stat title='File Uploads' value='S3 AWS' tag='Auto-Managed'/>
                            <Stat title='CI/CD' value='Github Actions' tag='Auto-Managed'/>
                            <Stat title='Database' value='RDS Postgres' tag='Auto-Managed'/>
                        </div>
                        <div className='margin'></div>  
                        <div className='flex'>  
                            <Stat title='Authentication' value='JWT' tag='Auto-Managed'/>
                            <Stat title='Stripe' value='Stripe' tag='API Key'/>
                            <Stat title='Session Manager' value='Node.js' tag='Auto-Managed'/>
                            <Stat title='Emails' value='AWS SES' tag='Transactional'/>
                        </div>
                        <div className='margin'></div>
                        <div className='flex'>  
                            <Stat title='HTTPS/CDN' value='Cloudfront' tag='Auto-Managed'/>
                            <Stat title='Build Pipeline' value='AWS Codebuild' tag='Auto-Managed'/>
                            <Stat title='Container' value='Docker' tag='Pre-Built'/>
                            <Stat title='Monitoring' value='Cloudwatch' tag='Auto-Managed'/>
                        </div>
                        <div className='margin'></div>
                        <div className='flex'>  
                            <Stat title='Customer Support' value='Intercom' tag='API'/>
                            <Stat title='Analytics' value='Google Analytics' tag='API'/>
                            <Stat title='Ads' value='Facebook Pixel' tag='API'/>
                            <Stat title='SEO' value='Google Search' tag='API'/>
                        </div>
                    </div>
                    <div className='margin'></div>
                    <div className='section'>   
                        <h3 className='grey'>Your New App</h3>  
                        <div className='margin'></div>
                        <div className='flex'>  
                            <Stat title='React' value='React' tag='Frontend'/>
                            <Stat title='Node.js' value='Node.js' tag='Backend'/>
                            <Stat title='Postgres' value='Postgres' tag='Database'/>
                            <Stat title='Stripe' value='Stripe' tag='Payment'/>
                        </div>
                    </div>
                    <div className='margin'></div>
                    <div className='section'>   
                        <h3 className='grey'>Backoffice </h3>  
                        <div className='margin'></div>
                        <div className='flex'>  
                            <Stat title='Internal' value='Analytics' tag='GA4'/>
                            <Stat title='Internal' value='Financials' tag='Baremetrics'/>
                            <Stat title='Internal' value='Hosting' tag='Heroku'/>
                            <Stat title='Internal' value='Support' tag='ZenDesk'/>
                        </div>
                        <div className='margin'></div>
                        <div className='flex'>  
                            <Stat title='Internal' value='CI/CD' tag='CircleCI'/>
                            <Stat title='Internal' value='CRM' tag='Hubspot'/>
                            <Stat title='Internal' value='Email' tag='Mailgun'/>
                            <Stat title='Internal' value='Certificates' tag='Cloudflare'/>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </>
            )
    }
    
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }
  
  export default connect(mapStateToProps)(LandingPage);
