/* Create Brand Picker Modal */
/* Should be able to select a brand from a list of brands */
/* Should be able to search for a brand */
/* Should be able to create a new brand */

import React, { useState, useRef } from 'react';
import { connect } from "react-redux";
import Modal from "..";
import "./index.scss";
import { Check, ArrowBack, ArrowForward } from "@mui/icons-material";
import Button from "../../Button";
import { addMonths, getDaysInMonth, startOfMonth } from 'date-fns';
import { setDate } from '../../../redux/actions/date';
import store from '../../../redux/store';
import { setModal } from '../../../redux/actions/modal';
import { setApp } from '../../../redux/actions/app';

const CalendarModal = (props) => {
    const [selectedRange, setSelectedRange] = useState({ start: null, end: null });
    const [selectedOption, setSelectedOption] = useState('last30');
    const [currentMonths, setCurrentMonths] = useState({
        left: new Date(),
        right: addMonths(new Date(), 1)
    });

    const dateOptions = [
        { label: 'Today', value: 'today', start : new Date(), end : new Date() },
        { label: 'Yesterday', value: 'yesterday', start : new Date(new Date().setDate(new Date().getDate() - 1)), end : new Date(new Date().setDate(new Date().getDate() - 1)) },
        { label: 'Last 7 days', value: 'last7', start : new Date(new Date().setDate(new Date().getDate() - 7)), end : new Date() },
        { label: 'Last 30 days', value: 'last30', start : new Date(new Date().setDate(new Date().getDate() - 30)), end : new Date() },
        { label: 'Last 90 days', value: 'last90', start : new Date(new Date().setDate(new Date().getDate() - 90)), end : new Date() },
        { label: 'Last 365 days', value: 'last365', start : new Date(new Date().setDate(new Date().getDate() - 365)), end : new Date() },
        { label: 'Last month', value: 'lastMonth', start : new Date(new Date().setMonth(new Date().getMonth() - 1, 1)), end : new Date(new Date().setMonth(new Date().getMonth(), 0)) },
        { label: 'Last 12 months', value: 'last12Months', start : new Date(new Date().setMonth(new Date().getMonth() - 12)), end : new Date() },
        { label: 'Last year', value: 'lastYear', start : new Date(new Date().setFullYear(new Date().getFullYear() - 1, 0, 1)), end : new Date(new Date().setFullYear(new Date().getFullYear() - 1, 11, 31)) }
    ];

    const handlePrevMonth = (side) => {
        if (side === 'right') {
            const leftDate = currentMonths.left;
            const newRightDate = addMonths(currentMonths.right, -1);
            
            // Only allow if the new right date would be after the left date
            if (newRightDate > leftDate) {
                setCurrentMonths(prev => ({
                    ...prev,
                    right: newRightDate
                }));
            }
        } else {
            // For left calendar, ensure right calendar stays ahead
            const newLeftDate = addMonths(currentMonths.left, -1);
            setCurrentMonths(prev => ({
                ...prev,
                left: newLeftDate,
                // If right calendar would become equal/less than new left date, move it forward
                right: prev.right <= addMonths(newLeftDate, 1) ? addMonths(newLeftDate, 1) : prev.right
            }));
        }
    };

    const handleNextMonth = (side) => {
        if (side === 'left') {
            const rightDate = currentMonths.right;
            const newLeftDate = addMonths(currentMonths.left, 1);
            
            // Only allow if the new left date would be before the right date
            if (newLeftDate < rightDate) {
                setCurrentMonths(prev => ({
                    ...prev,
                    left: newLeftDate
                }));
            }
        } else {
            setCurrentMonths(prev => ({
                ...prev,
                right: addMonths(prev.right, 1)
            }));
        }
    };
    
    const handleDateClick = (day) => {
        const clickedDate = new Date(day);
        
        if (!selectedRange.start || (selectedRange.start && selectedRange.end)) {
            // First click or starting a new range
            setSelectedRange({ 
                start: clickedDate, 
                end: null 
            });
        } else {
            // Second click - completing the range
            const start = new Date(selectedRange.start);
            
            if (clickedDate < start) {
                setSelectedRange({ 
                    start: clickedDate, 
                    end: start 
                });
            } else {
                setSelectedRange({ 
                    start: start, 
                    end: clickedDate 
                });
            }
        }
    };

    const renderCalendarGrid = (date) => {
        const weekDays = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
        const daysInMonth = getDaysInMonth(date);
        const firstDayOfMonth = startOfMonth(date).getDay();
        const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);
        
        // Calculate offset for first day of month
        const offset = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;
        
        return (
            <div className="calendar-container">
                <div className="weekdays">
                    {weekDays.map(day => (
                        <div key={day} className="weekday">{day}</div>
                    ))}
                </div>
                <div className="days-grid">
                    {[...Array(offset)].map((_, index) => (
                        <div key={`empty-${index}`} className="calendar-day empty"></div>
                    ))}
                    {days.map(day => {
                        const currentDate = new Date(date.getFullYear(), date.getMonth(), day);
                        const isStart = selectedRange.start && 
                            currentDate.getTime() === selectedRange.start.getTime();
                        const isEnd = selectedRange.end && 
                            currentDate.getTime() === selectedRange.end.getTime();
                        const isInRange = selectedRange.start && selectedRange.end && 
                            currentDate >= selectedRange.start && 
                            currentDate <= selectedRange.end;
                        
                        let className = 'calendar-day';
                        if (isStart) className += ' selected range-start';
                        if (isEnd) className += ' selected range-end';
                        if (isInRange) className += ' in-range';
                        
                        return (
                            <div
                                key={day}
                                className={className}
                                onClick={() => handleDateClick(currentDate)}
                            >
                                {day}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    const formatDate = (date) => {
        if (!date) return '';
        return date.toLocaleDateString('default', { 
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        });
    };

    const renderCalendar = (side) => {
        const isLeft = side === 'left';
        const date = currentMonths[side];
        const selectedDate = isLeft ? selectedRange.start : selectedRange.end;
        
        /* Should disable the arrow if the next/previous month is the same as the other calendar */     
        return (
            <div className="calendar-section">
                <div className="date-input">
                    {selectedDate ? formatDate(selectedDate) : 
                        `Select ${isLeft ? 'Start' : 'End'} Date`}
                </div>
                <div className="calendar-header">
                    <ArrowBack 
                        className={`arrow ${!isLeft && addMonths(currentMonths.left, 1) >= currentMonths.right ? 'disabled' : ''}`}
                        onClick={() => handlePrevMonth(side)}
                    />
                    <h4>{date.toLocaleString('default', { month: 'long' })} {date.getFullYear()}</h4>
                    <ArrowForward 
                        className={`arrow ${isLeft && addMonths(currentMonths.right, -1) <= currentMonths.left ? 'disabled' : ''}`}
                        onClick={() => handleNextMonth(side)}
                    />
                </div>
                {renderCalendarGrid(date)}
            </div>
        );
    };

    const handleClose = () => {
        store.dispatch(setModal(null));
    };

    const handleApply = async () => {
        if (selectedRange.start && selectedRange.end) {
            await store.dispatch(setDate({
                start: selectedRange.start, 
                end: selectedRange.end
            }));
            
            /* send call to backend to update the date range */
            await store.dispatch(setApp(props.app));
            handleClose();
        }
    };
    
    const modalRef = useRef();

    const calculateDatesForOption = (option) => {
        const today = new Date();
        const start = new Date();
        const end = new Date();
        
        switch (option) {
            case 'today':
                return { start: today, end: today };
            case 'yesterday':
                start.setDate(today.getDate() - 1);
                end.setDate(today.getDate() - 1);
                return { start, end };
            case 'last7':
                start.setDate(today.getDate() - 7);
                return { start, end };
            case 'last30':
                start.setDate(today.getDate() - 30);
                return { start, end };
            case 'last90':
                start.setDate(today.getDate() - 90);
                return { start, end };
            case 'last365':
                start.setDate(today.getDate() - 365);
                return { start, end };
            case 'lastMonth':
                start.setMonth(today.getMonth() - 1, 1);
                end.setMonth(today.getMonth(), 0);
                return { start, end };
            case 'last12Months':
                start.setMonth(today.getMonth() - 12);
                return { start, end };
            case 'lastYear':
                start.setFullYear(today.getFullYear() - 1, 0, 1);
                end.setFullYear(today.getFullYear() - 1, 11, 31);
                return { start, end };
            default:
                return { start: null, end: null };
        }
    };

    const handleDateOptionClick = (option) => {
        setSelectedOption(option.value);
        
        // Get the dates from the option directly
        const start = option.start;
        const end = option.end;
        
        // Set the selected range
        setSelectedRange({ start, end });
        
        // Update calendar months to show relevant dates
        setCurrentMonths({
            left: new Date(start.getFullYear(), start.getMonth(), 1),
            right: new Date(end.getFullYear(), end.getMonth(), 1)
        });
    }

    return (
        <Modal ref={modalRef} keyItem='Calendar' hasCloseButton={false}>
            <div className='calendar-modal'>
                <div className='date-options'>
                    {dateOptions.map((option, index) => (
                        <div 
                            key={index} 
                            className={`date-option ${selectedOption === option.value ? 'selected' : ''}`}
                            onClick={() => handleDateOptionClick(option)}
                        >
                            <h4 className='date-option-label'>{option.label}</h4>
                            {selectedOption === option.value && <Check />}
                        </div>
                    ))}
                </div>
                <div className='calendars-container'>
                    <div className='calendar-grids'>
                        {renderCalendar('left')}
                        {renderCalendar('right')}
                    </div>
                </div>
            </div>
            <div className="calendar-actions">
                <Button className="button secondary" onClick={handleClose}>
                    <h4>Cancel</h4>
                </Button>
                <Button className="button primary" onClick={handleApply}>
                    <h4>Apply</h4>
                </Button>
            </div>
        </Modal>
    );
};

const mapStateToProps = (state) => ({   
    date: state.date,
    app: state.app
});

export default connect(mapStateToProps)(CalendarModal);
