/* Create Graph Component */
// Path: webapp/client/src/app/components/Graph/index.js

import React, { useState, useMemo } from 'react';
import './index.scss';
import { connect } from 'react-redux';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  defaults,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import colors from '../../../config/_export.scss';
import { formatDate, formatNumberWithCommas } from '../../lib/Helpers';
import Toggle from '../Toggle';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

defaults.font.family = colors.fontFamily;

const Graph = ({ title, xAxis = [], yAxis = {}, cumulative = false, prefix }) => {
  const [isCumulative, setIsCumulative] = useState(cumulative);

  /* If no data is provided, display a message */
  const noDataAvailable = !yAxis.data || yAxis.data.length === 0;

  const cumulativeData = useMemo(() => {
    if (!isCumulative || !yAxis.data) return yAxis.data;
    return yAxis.data.map(dataset => {
      if (!Array.isArray(dataset.values)) return [];
      return dataset.values.reduce((acc, curr, index) => {
        if (curr !== null) {
          acc[index] = (acc[index - 1] || 0) + curr;
        } else {
          acc[index] = null;
        }
        return acc;
      }, []);
    });
  }, [isCumulative, yAxis.data]);
  
  if (noDataAvailable) {
    return (
      <div className='box-container padded full-width'>
        <h4 className='grey'>{title}</h4>
        <p>No data available for the graph.</p>
      </div>
    );
  }
  
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true, // Changed to true to display the legend
        position: 'top',
        align: 'end',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          padding: 20,
        },
      },
      title: {
        display: false,
      },
      tooltip: {
        intersect: false,
        mode: 'index',
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              const formattedValue = formatNumberWithCommas(context.parsed.y, 0);
              const dataPrefix = yAxis.data.find(dataset => dataset.values === context.dataset.data)?.prefix || '';
              console.log(dataPrefix);
              label += dataPrefix ? `${dataPrefix}${formattedValue}` : formattedValue;
            }
            return label;
          }
        }
      },
    },
    scales: {
      x: {
        ticks: {
          callback: function(value, index) {
            const date = new Date(this.getLabelForValue(index));
            return formatDate(date, { year: 'none', month: 'short', day: 'numeric' }); // Format date as 'Oct 23'
          }
        }
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: function(value) {
            const formattedValue = formatNumberWithCommas(value, 0);
            return prefix ? `${prefix}${formattedValue}` : formattedValue;
          }
        },
      },
    },
    elements: {
      point: {
        radius: 0, // Hides points on the line
      },
      line: {
        tension: 0.4, // Optionally adds some curve between points
      },
    },
  };
  
  const data = {
    labels: xAxis,
    datasets: yAxis.data.map((dataset, index) => ({
      label: dataset.label || `Dataset ${index + 1}`,
      data: isCumulative && cumulativeData ? cumulativeData[index] : dataset.values,
      borderColor: index === 0 ? colors.color1 || colors.primaryColor : colors.color2 || colors.secondaryColor,
      backgroundColor: index === 0 ? `${colors.color1 || colors.primaryColor}33` : 'transparent',
      borderDash: dataset.style === 'dashed' ? [5, 5] : [],
      fill: index === 0,
      spanGaps: true,
    })),
  };
  
  return (
    <div className='box-container padded full-width'>
      <div className='flex'>
        <h4 className='grey'>{title}</h4>
        <Toggle
          isActive={isCumulative}
          setIsActive={setIsCumulative}
          label="Cumulative"
        />
      </div>
      <div className='graph-container margin'>
        <Line redraw={true} options={options} data={data} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    date: state.date,
  };
};

export default connect(mapStateToProps)(Graph);
