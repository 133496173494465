import { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss';
import { MetaTags } from 'react-meta-tags';
import UserAPI from '../../../lib/User';
import NotificationHandlerSingleton from '../../../lib/NotificationHandler';

class AccountPage extends Component{
    constructor(props){
        super(props);
        this.state = {
            value : 0
        }
    }

    renderInfo = ({key, value}) => {
        return (
            <div className='fifty'>
                <div>
                    <h4 className='grey' style={{margin : 0}}> {key}</h4>
                </div>
                <div className='text-right'>
                    <h4 className=''> {value}</h4>
                </div>
            </div>
       
        )
    }

    recoverPassword = async () => {
        await UserAPI.requestResetPassword({email : this.props.user.email});
        // Inform on Modal
        NotificationHandlerSingleton.onNotification({message : "Password Recovery Email Sent", status : "success"})
    }

    logout = async () => {
        await UserAPI.logout();
        window.location.href = '/';
    }


    render = () => {
        const { user } = this.props;
        if(!user.id){return null};
        return (
            <div className='page'>
                <MetaTags>
                    <title>Account</title>
                    <meta name="description" content="Manage your Account" />
                    <meta property="og:title" content="Account" />
                </MetaTags>
                <div className='box-container padded'>
                    {/* User Data*/}
                    {this.renderInfo({key : 'Name', value : user.name})}
                    {this.renderInfo({key : 'Email', value : user.email})}
                    {this.renderInfo({key : 'User ID', value : user.id})}
                    <div className='divider small-margin'/>
                    <div className='fifty'>
                        <div>
                            <h4 className='grey' style={{margin : 0}}> Change Password </h4>
                        </div>
                        <a style={{cursor : 'pointer'}} onClick={this.recoverPassword} className='text-right'>
                            <h4 className='link'> Password Recovery </h4>
                        </a>
                    </div>
                    <div className='divider small-margin'/>
                    {/* Logout */}
                    <div className='fifty'>
                        <div>
                            <h4 className='grey' style={{margin : 0}}> Logout </h4>
                        </div>
                        <a style={{cursor : 'pointer'}} onClick={this.logout} className='text-right'>
                            <h4 className='link'> Logout </h4>
                        </a>
                    </div>
                </div>       
            </div>
        )
    }
    
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }

  export default connect(mapStateToProps)(AccountPage);
