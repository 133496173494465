import React from 'react';
import './index.scss'; // Create a corresponding CSS file for styling

const Tooltip = ({ message, children }) => {
    return (
        <div className="tooltip-container">
            {children}
        </div>
    );
};

export default Tooltip;
