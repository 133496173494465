import './index.scss';
import { useNavigate } from "react-router-dom";
import StepProcess from '../../../../components/StepProcess';
import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';
import Button from '../../../../components/Button';
const LoadingPage = (props) => {
    const navigate = useNavigate();
    const { app, setLoading, status, currentStep } = props;
    const { width, height } = useWindowSize();

    // Redirect to HomePage when the process is complete (step 7)
    if (currentStep === 7) {
        navigate('/app/home');
    }

    
    return (
        <div>
            {currentStep === 6 && <Confetti width={width} height={height} />}
            <div className='container'>
                <h3 className='grey'>{status}<h3>{app.name}</h3></h3>
                <h5 className='grey'>This process can take several minutes</h5>
                {currentStep === 6 && (
                    <div className='margin'>
                        <Button className='button' onClick={() => navigate('/home')}>
                            Check the App 
                        </Button>
                    </div>
                )}
            </div>
            <StepProcess currentStep={currentStep} />
        </div>
    )
}

export default LoadingPage;
