import { PAGE_SIZE } from "../../config";

export function removeEmptyArraysAndKeys(r) {
  var obj = {...r};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (Array.isArray(obj[key])) {
          obj[key] = obj[key].filter(value => value !== undefined && value !== null);
          
          if (obj[key].length === 0) {
            delete obj[key];
          }
        } else if (obj[key] === null || obj[key] === undefined) {
          delete obj[key];
        }
      }
    }
    return obj;
  }
  

export function getSizeAndOffsetFromPageNumber(page){
  return {
    size : PAGE_SIZE,
    offset : (page*PAGE_SIZE)-PAGE_SIZE
  }
}

export function roundUpIfNotInteger(value){
    if (typeof value !== 'number') {
        throw new Error("Input must be a numeric value");
    }

    if (Number.isInteger(value)) {
        return value;
    } else {
        return Math.ceil(value);
    }
}

export function formatPercentage(percentage){
    return `${percentage.toFixed(1)}%`;
}

function getIntersectionObjectCaseInsensitive(obj, targetArray) {
    let intersectionObject = {};

    // Iterate through all keys in the object
    for (let key in obj) {
        if (obj.hasOwnProperty(key) && Array.isArray(obj[key])) {
            // Convert all strings to lowercase for case-insensitive comparison
            let lowercaseTargetArray = targetArray.map(value => value.toLowerCase());
            
            // Use filter to keep values that are present in both arrays (case-insensitive)
            let keyIntersection = obj[key].filter(value => lowercaseTargetArray.includes(value.toLowerCase()));

            // Add the keyIntersection to the result object
            intersectionObject[key] = keyIntersection;
        }
    }

    return intersectionObject;
}

export function formatDuration(durationInSeconds) {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = Math.floor(durationInSeconds % 60);
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    return `${minutes}:${formattedSeconds}`;
}

export const returnFileNameExtensionFromURL = (fileURL) => {
return (/[.]/.exec(fileURL)) ? "." + /[^.]+$/.exec(fileURL) : undefined;
}
export const  formatNumberWithCommas = (number, precision=2) => {
    
    /* Format to 2 decimal places */
    number = parseFloat(number).toFixed(precision);
    
    // Convert the number to a string
    let numberString = number ? number.toString() : '';
    
    // Use a regular expression to add commas every 3 digits
    numberString = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    
    return numberString;
}

export function isValidEmail(email) {
  // Regular expression for a basic email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export function isValidPassword(password) {
  // Check if the password is not null, undefined, or an empty string
  if (!password || typeof password !== 'string') {
    return false;
  }

  // Check if the password has more than 7 characters
  return password.length > 7;
}

export function isValidName(name) {
  // Check if the name is not null, undefined, or an empty string
  if (!name || typeof name !== 'string') {
    return false;
  }

  // Check if the name length is within the valid range (1 to 30 characters)
  return name.length > 3 && name.length <= 30;
}

export function addOneMonth(date) {
  // Copy the original date to avoid mutating it
  const newDate = new Date(date);

  // Increment the month by 1
  newDate.setMonth(newDate.getMonth() + 1);

  return newDate;
}

export function generateRandomString(length=10) {
  // Define all the characters that can be used in the random string
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  // Initialize the result string
  let result = '';

  // Generate a random character and add it to the result string
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
}


export const getCSSVariableValue = (variableName) => {
  return getComputedStyle(document.documentElement).getPropertyValue(variableName).trim();
};

export function truncateString(str, length){
   if (str.length <= length) {
        return str;
    } else {
        return str.slice(0, length) + '...';
    }
}

export function truncateStringByLimitWords(str, limit){
  const words = str.split(' ');
  if (words.length <= limit) {
    return str;
  } else {
    return words.slice(0, limit).join(' ');
  }
}

export function priceFormat(price){
    price = price/3 + 0.97;
    /* Price has to end in seven */
    price = price.toFixed(2);
    price = price.substring(0, price.length - 1) + '7';
    return `$${price}`;
}

export function fromDate(date){
    return new Date(date).toLocaleDateString();
}

export function formatDate(date, options={}){
    let finalOptions = { month: 'short', day: 'numeric', year: 'numeric' };
    if(options.year === 'none'){
      finalOptions.year= undefined;
    }
    /* should output "Set 12, 2024" */
    return new Date(date).toLocaleDateString('en-US', finalOptions);
}

export function formatName(name){
  /* Should handle names like "JOHN ASD" */
  return name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
}

export function formatPrice(price){
  if(price === null || price === undefined || price === 0){
    return 'No Payment';
  }
  /* convert to string */
  let priceNumber = parseFloat(price);   
  /* convert to 2 decimal places */
  return `$${formatNumberWithCommas(priceNumber.toFixed(2))}`;
}