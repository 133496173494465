import './index.scss';
import CreateName from '../CreateName';
import UploadLogo from '../UploadLogo';

const SubmissionPage = (props) => {
    const { app, set } = props;

    return (
        <div className='app-create-container'>
            <h2>Create your Application</h2>
            <div className='divider margin'></div>
            <div className='form-section'>
                <div className='upload-section'>
                    <UploadLogo set={set} app={app} description='Upload your Logo' />
                </div>
                <div className='input-section'>
                    <CreateName set={set} />
                </div>
               
            </div>
            <div className='box-container'>
                <h3>{app.name}</h3>
                <img src={app.logo} alt='logo' className='small-logo' />
            </div>
        </div>
    )
}

export default SubmissionPage;
