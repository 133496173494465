import React, { useState, useCallback } from 'react';
import UploadArea from "../../../../components/UploadArea";
import './index.scss';
import { setNotification } from '../../../../redux/actions/notification';
import store from "../../../../redux/store";

const UploadLogo = (props) => {
    const [error, setError] = useState(null);

    const set = useCallback(({ type, data }) => {
        setError(null);
        props.set({ type, data });
    }, [props.set]);

    const handleError = useCallback((errorMessage) => {
        setError(errorMessage);
        store.dispatch(setNotification({error: true, message: errorMessage}));
    }, []);

    return (
        <div className='upload-logo-container'>
            {props.app.logo 
                ? 
                    <img src={props.app.logo} alt='logo' className="logo"/>
                :
                    <UploadArea 
                        type={'logo'} 
                        set={set} 
                        onError={handleError}
                        description={props.description}
                    />
            }
            {error && <p className="error-message">{error}</p>}
        </div>
    )
}

export default React.memo(UploadLogo);
