import React, { useState } from 'react';
import './index.scss';
import WorldMap from "react-svg-worldmap";
import colors from '../../../config/_export.scss';
import ReactCountryFlag from "react-country-flag";
import { formatNumberWithCommas } from '../../lib/Helpers';
import Stat from '../Stat';

const WorldGraph = ({ data, title, tag }) => {
	const [hoveredCountry, setHoveredCountry] = useState(null);

	const totalActiveUsers = data.reduce((sum, country) => sum + country.activeUsers, 0);

	const sortedData = [...data].sort((a, b) => b.activeUsers - a.activeUsers);

	const customStyleFunction = ({ countryValue, minValue, maxValue, country, color }) => {
		// Default style for countries without data
		let style = {
			fill: colors.backgroundColor,
			stroke: colors.boxBackgroundColor,
			strokeWidth: 3,
			strokeOpacity: 1,
			cursor: 'pointer'
		};

		if (countryValue) {
			const opacityLevel = (countryValue - minValue) / (maxValue - minValue);
			style.fill = colors.primaryColor;  // Use primary color for countries with data
			style.fillOpacity = 0.2 + opacityLevel * 0.8;  // Adjust opacity based on value
		}

		return style;
	};

	const handleCountryHover = (country) => {
		setHoveredCountry(country);
	};

	const renderCountryList = () => {
		return (
			<div className="country-list">
				<div className="country-item total">
				</div>
				{sortedData.map((country) => {
					const percentage = (country.activeUsers / totalActiveUsers) * 100;
					return (
						<div
							key={country.code}
							className={`country-item ${hoveredCountry === country.code ? 'highlighted' : ''}`}
							onMouseEnter={() => setHoveredCountry(country.code)}
							onMouseLeave={() => setHoveredCountry(null)}
						>
							<ReactCountryFlag countryCode={country.code} svg className="country-flag" />
							<span className="country-name">{country.name}</span>
							<div className="country-info">
								<h6 className="active-users">{formatNumberWithCommas(country.activeUsers, 0)}</h6>
								<span className="percentage">({percentage.toFixed(1)}%)</span>
							</div>
							<div className="percentage-bar total-bar" style={{ width: '100%' }}></div>
							<div className="percentage-bar country-bar" style={{ width: `${percentage}%` }}></div>
						</div>
					);
				})}
			</div>
		)
	}

	return (
		<div className="box-container">
			<div className="world-graph">
				<div className="graph-header">
					<Stat title={title} tag={tag} value={formatNumberWithCommas(totalActiveUsers, 0)} />
					<div className="graph-content">
						<div className="graph-wrapper">
							<WorldMap
								color={colors.primaryColor}
								valueSuffix="users"
								data={data.map(country => ({
									country: country.code,
									value: country.activeUsers
								}))}
								size='responsive'
								backgroundColor={'transparent'}
								styleFunction={customStyleFunction}
								onHover={(country) => handleCountryHover(country ? country.countryCode : null)}
							/>
						</div>
					</div>
				</div>
				{renderCountryList()}
			</div>
		</div>
	);
};

export default WorldGraph;
