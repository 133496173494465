/* Create a DatePicker Component that when clicked opens a modal with a calendar to pick a date. */
import React, {Component} from 'react';
import Button from '../Button';
import { ArrowDropDown, CalendarMonthOutlined } from '@mui/icons-material';
import './index.scss';
import CalendarModal from '../Modal/Calendar';
import store from '../../redux/store';
import { setModal } from '../../redux/actions/modal';
import { connect } from 'react-redux';


export const getDisplayText = ({date}) => {
    // Predefined date ranges
    const ranges = {
        'Today': {  
            start: new Date(),
            end: new Date()
        },
        'Yesterday': {
            start: new Date(new Date().setDate(new Date().getDate() - 1)),
            end: new Date(new Date().setDate(new Date().getDate() - 1))
        },
        'Last 7 days': {
            start: new Date(new Date().setDate(new Date().getDate() - 7)),
            end: new Date()
        },
        'Last 30 days': {
            start: new Date(new Date().setDate(new Date().getDate() - 30)),
            end: new Date()
        },
        'Last 90 days': {
            start: new Date(new Date().setDate(new Date().getDate() - 90)),
            end: new Date()
        },
        'Last 365 days': {
            start: new Date(new Date().setDate(new Date().getDate() - 365)),
            end: new Date()
        },
        'Last month': {
            start: new Date(new Date().setMonth(new Date().getMonth() - 1, 1)),
            end: new Date(new Date().setMonth(new Date().getMonth(), 0))
        },
        'Last 12 months': {
            start: new Date(new Date().setMonth(new Date().getMonth() - 12)),
            end: new Date()
        },
        'Last year': {
            start: new Date(new Date().setFullYear(new Date().getFullYear() - 1, 0, 1)),
            end: new Date(new Date().setFullYear(new Date().getFullYear() - 1, 11, 31))
        }
    };

    // Check if current date range matches any predefined range
    for (const [label, range] of Object.entries(ranges)) {
        // Normalize dates by setting them to start of day
        const normalizedStart = new Date(range.start.setHours(0, 0, 0, 0));
        const normalizedEnd = new Date(range.end.setHours(23, 59, 59, 999));
        const normalizedDateStart = date.start ? new Date(date.start.setHours(0, 0, 0, 0)) : null;
        const normalizedDateEnd = date.end ? new Date(date.end.setHours(23, 59, 59, 999)) : null;

        if (normalizedDateStart?.getTime() === normalizedStart.getTime() &&
            normalizedDateEnd?.getTime() === normalizedEnd.getTime()) {
            return label;
        }
    }

    // If no match, return custom date range format
    if (date.start && date.end) {
        return `${formatDate(date.start)} → ${formatDate(date.end)}`;
    }
    
    return 'Select date range';
}

const formatDate = (date) => {
    return date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
};


const DatePicker = ({ date }) => {
    
    const openCalendar = () => {  
        store.dispatch(setModal('Calendar'))
    }
    return (
        <div className='date-picker'>
            <div className='min-width'>
                <Button className='button button-date' onClick={openCalendar}>
                    <CalendarMonthOutlined/>
                    <h5 className='grey'>{getDisplayText({date})}</h5>
                </Button>
            </div>
            <div className='calendar'>
                <CalendarModal/>
            </div>
        </div>
    );
}



function mapStateToProps(state) {
    return {
        user : state.user,
        date : state.date
    };
}

export default connect(mapStateToProps)(DatePicker);
