/* use dot env */
import GENERAL_INFO_DEFAULT from '../general_info';

export const API_URL = process.env.REACT_APP_API_URL;

export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

export const PAGE_SIZE = process.env.PAGE_SIZE || 20;

export const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

export const GOOGLE_ANALYTICS_MEASUREMENT_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID|| '';

export const GOOGLE_ID = process.env.REACT_APP_GOOGLE_ID|| '';

if(!GOOGLE_ID){
    console.log('Google Id for Login is not set');
}

if(!GOOGLE_ANALYTICS_MEASUREMENT_ID){
    console.log('Google Analytics Measurement Id is not set');
}

export const IS_PRODUCTION = (process.env.REACT_APP_ENV === 'production') ? true : false;

export const FACEBOOK_PIXEL = process.env.REACT_APP_FACEBOOK_PIXEL|| '';

export const STRIPE_LINK = process.env.REACT_APP_STRIPE_LINK;

if(!STRIPE_LINK){
    console.log('Stripe Payment is not set');
}

export const GENERAL_INFO = GENERAL_INFO_DEFAULT;
