import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import createRootReducer from './reducers';
import {thunk} from 'redux-thunk';
const history = createBrowserHistory();

function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    compose(
      applyMiddleware(thunk),
    ),
  )

  return store
}

const store = configureStore();
export default store;
