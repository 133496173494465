
  
export const SET_NOTIFICATION = 'SET_NOTIFICATION';

export function setNotification(data) {
    return {
      type: SET_NOTIFICATION,
      action : data
    };
}


