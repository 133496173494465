import React, { useState, useCallback } from 'react';
import './index.scss';
import ConfirmationModal from '../../../components/ConfirmationModal';
import { useSelector, useDispatch } from 'react-redux';
import { deleteApp, updateApp } from '../../../redux/actions/appActions';
import Button from '../../../components/Button';
import InputText from '../../../components/InputText';
import TabsContainer from '../../../components/TabsContainer';
import Info from '../../../components/Info';
import APIConnection from '../../../api';
import UploadArea from '../../../components/UploadArea';
import { setNotification } from '../../../redux/actions/notification';

const SettingsPage = () => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [formData, setFormData] = useState({});
    const app = useSelector(state => state.app);
    const dispatch = useDispatch();

    React.useEffect(() => {
        setFormData({
            fullName: app.fullName,
            description: app.description,
            topics: app.topics,
            metadata: app.metadata,
            logo: app.branding?.logo
        });
    }, [app]);

    const handleDeleteApp = async () => {
        setIsDeleting(true);
        try {
            await deleteApp(app.id);
        } catch (error) {
            console.error('Error deleting app:', error);
        } finally {
            setIsDeleting(false);
            setShowDeleteModal(false);
        }
    };

    const handleLogoUpdate = useCallback(({ type, data }) => {
        setFormData(prev => ({
            ...prev,
            logo: data  
        }));
    }, []);

    const handleError = useCallback((errorMessage) => {
        dispatch(setNotification({ error: true, message: errorMessage }));
    }, [dispatch]);

    const handleSubmit = async () => {
        try {
            const response = await APIConnection.editApp({ 
                id: app.id,
                app: formData
            });
            if (response.success) {
                dispatch(updateApp({ ...app, ...formData }));
            }
        } catch (error) {
            console.error('Error updating app:', error);
            handleError('Failed to update application');
        }
    };

    const tabs = [
        {
            key: 'general',
            label: 'General',
            content: (
                <div className='margin flex'>
                    <div className='box-container padded' >
                        <div className='flex'>
                            <div className='left'>
                                <InputText 
                                    type='name'
                                    onChange={(type, value) => setFormData(prev => ({ ...prev, fullName: value }))}
                                    title="Application Name"
                                    value={formData.fullName}
                                    placeholder={app.fullName}
                                    isDisabled={true} 
                                    />
                            </div>
                            <div className='right'>
                                <div className='current-logo'>
                                    <UploadArea 
                                        title='Logo'
                                        type='logo'
                                        set={handleLogoUpdate}
                                        onError={handleError}
                                        description='Logo'
                                        currentImage={formData?.logo}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='fifty small-margin'>
                            <Button onClick={handleSubmit} className='button left'>
                                <h4>Update</h4>
                            </Button>
                        </div>
                    </div>
                    <div className='box-container padded'>
                        <h4 className='grey'>Application Details</h4>
                        <div className='info-grid'>
                            <Info title='Application ID' value={app.id} />
                            <Info title='Created At' value={new Date(app.created_at).toLocaleDateString()} />
                        </div>
                    </div>
                </div>
            )
        },
        {
            key: 'danger',
            label: 'Danger Zone',
            content: (
                <div className='margin'>
                    <h4 className='grey'>Danger Zone</h4>
                    <div className='box-container danger-zone'>
                        <div className='flex space padded'>
                            <div>
                                <h4>Delete Application</h4>
                                <p className='grey'>Once you delete an application, there is no going back. Please be certain.</p>
                            </div>
                            <Button className="button danger" onClick={() => setShowDeleteModal(true)}>
                                <h4>Delete Application</h4>
                            </Button>
                        </div>
                    </div>
                </div>
            )
        }
    ];

    return (
        <div className="page">
            <div className="main-content">
                <TabsContainer tabs={tabs} />
            </div>
            
            {showDeleteModal && (
                <ConfirmationModal
                    message="Are you sure you want to delete this application? This action cannot be undone."
                    onConfirm={handleDeleteApp}
                    onCancel={() => setShowDeleteModal(false)}
                />
            )}
            {isDeleting && <div className="loading-animation">Deleting...</div>}
        </div>
    );
};

export default SettingsPage;
