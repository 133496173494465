import { SET_APP } from "../actions/app";

const initialState = {};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_APP : {     
            return action.action;
        }
      default:
        return state;
    }
  }
  