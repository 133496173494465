import { connect } from "react-redux";
import "./index.scss";
import Button from "../Button";
import ProgressBar from "../ProgressBar";
import { useState } from "react";

const Form = (props) => {
    var { user, pages, finalText, onSubmit, title, loading, onStepChange, stepProcess } = props;
    /* Create state of progress */
    const [progress, setProgress] = useState(2);
    const [page, setPage] = useState(0);

    if(!user) return null;

    const next = () => {
        setPage(page + 1);
        if(page + 1 == 1){
            onSubmit();
        }
        /* set progress in % proportional to page */
        setProgress(((page + 1) / (pages.length)) * 100);
        if(onStepChange) onStepChange(page + 1);
    }

    const back = () => {
        setPage(page - 1);
        /* set progress in % proportional to page */
        setProgress(((page - 1) / (pages.length)) * 100);
        if(onStepChange) onStepChange(page - 1);
    }

    const renderFooter = () => {        
        if(loading) return null;
        return (
            <div className="modal-footer">
                <div className="grid">
                    {/* Back Button */}
                    {page > 0 ? <Button className='button no-bg' onClick={() => back()}>
                        <h5 className="grey">Back</h5>
                    </Button>   : null}
                    <Button className={'button'} onClick={() => next()}>
                        <h5>Next</h5>
                    </Button>
                </div>  
            </div>
        )
    }

    const renderLastPage = () => {
        return (
            <div className="container-center">
                {/* Last Page */}
                <div className="inside">
                    <h4 className="center"> {finalText}</h4>
                    <Button className='button' onClick={onSubmit}>
                        <h4>Submit</h4>
                    </Button>
                </div>
            </div>
        )
    }

    return (
        <div className="total-page">
            <div className='background-modal'/>
            <div className='modal-middle modal-box modal-create-app'>
                {/* Header Content */}
                <h3 className="grey">{title}</h3>
                {/* Progress Bar */}    
                <div>
                    <ProgressBar progress={progress}/>
                </div>
                {/* Contents of Step */}
                <div className="form-content">
                    {pages.map((p, index) => (
                        <div key={index}>
                            {page == index && p}
                        </div>
                    ))}
                    {/* Last Page */}
                    {page == pages.length ? renderLastPage() : null}
                </div>
                {/* Footer Content */}
                <div className="form-footer">
                    {/* Step Process */}
                    {stepProcess}
                    {/* Render Footer */}
                    {renderFooter()}
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        user : state.user
    };
}

export default connect(mapStateToProps)(Form);
