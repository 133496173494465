import React, { useEffect, useState } from 'react';
import './index.scss';
import WorldGraph from '../../../components/WorldGraph';
import LinearGraph from '../../../components/LinearGraph';
import Stat from '../../../components/Stat';
import { formatNumberWithCommas,  formatPercentage } from '../../../lib/Helpers';
import { connect } from 'react-redux';
import SkeletonContainer from '../../../components/SkeletonContainer';

const AnalyticsPage = ({ app }) => {
    const [stats, setStats] = useState({});
    const [worldGraphData, setWorldGraphData] = useState([]);
    const [linearGraphData, setLinearGraphData] = useState([]);

    useEffect(() => {
        fetchData();
    }, [app.changeId]);

    const fetchData = async () => {
        if (app && app.id && app.sessions) {
            const data = app.sessions;
            console.log(data);
            if (data) {
                setStats({ 
                    total : {
                        visitors : data.total.visitors,
                        uniqueVisitors : data.total.uniqueVisitors,
                        sessionTime : data.total.sessionTime,
                        bounceRate : data.total.bounceRate
                    },
                    withinDateRange : {
                        visitors : data.withinDateRange.visitors,
                        uniqueVisitors : data.withinDateRange.uniqueVisitors,
                        sessionTime : data.withinDateRange.sessionTime
                    }
                })
                setWorldGraphData(Object.entries(data.withinDateRange.sessions.byCountry).map(([country, users]) => ({
                    name: country,
                    code: country, // Assuming country code is the same as the name
                    activeUsers: users
                })));
                setLinearGraphData(Object.entries(data.withinDateRange.sessions.byReferrer).map(([referrer, visitors]) => ({
                    name: referrer,
                    visitors: visitors,
                    // Add more fields as needed
                })));
            }
        }
    };
    
    if(!stats || !worldGraphData || !linearGraphData || !stats.total || !stats.withinDateRange) {
        return (
            <div className='page'>
                <SkeletonContainer count={7} type='list'/>
            </div>
        )
    }
    
    return (
        <div className="page">
            <div className='flex'>
                <Stat title={'Sessions'} tag={'Total'} value={formatNumberWithCommas(stats.total.visitors, 0)} />
                <Stat title={'Unique Visitors'} tag={'Total'} value={formatNumberWithCommas(stats.total.uniqueVisitors, 0)} />
                <Stat title={'Session Time'} tag={'Total'} value={`${parseInt(stats.total.sessionTime/1000)}s`} />
                <Stat title={'Bounce Rate'} tag={'Total'} value={`${formatPercentage(stats.total.bounceRate*100)}`} />
            </div>
            <div className="margin"></div>
            <div className="flex">
                <WorldGraph 
                    tag={'Date'}
                    data={worldGraphData} 
                    title='Active Users by Country' 
                />
                <LinearGraph 
                    tag={'Date'}
                    data={linearGraphData} 
                    title='Visitors by Source' 
                />
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    app: state.app
});

export default connect(mapStateToProps)(AnalyticsPage);
