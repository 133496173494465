/* Create Toggle Component */

import React from 'react';
import './index.scss';

const Toggle = ({ isActive, setIsActive, label }) => {
    return (
        <div className="toggle-container">
            {label && <span className="toggle-label">{label}</span>}
            <label className="toggle-switch">
                <input
                    type="checkbox"
                    checked={isActive}
                    onChange={() => setIsActive(!isActive)}
                />
                <span className="slider"></span>
            </label>
        </div>
    );
}

export default Toggle;
