
import React from 'react';
import './index.scss';

const CodePage = () => {
    return (
        <div className="page">
            {/* Insert your Code components here */}
        </div>
    );
}

export default CodePage;
