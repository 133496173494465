import React from 'react';
import './index.scss';
 import colors from '../../../config/_export.scss';

const bubbleColors = [
    colors.primaryColor,
    colors.redColor,
    colors.secondaryColor
];

const BubbleGraph = ({ data, title }) => {
  const totalValue = data.reduce((sum, item) => sum + item.value, 0);
  const nonZeroData = data.filter(item => item.value > 0);
  
  return (
    <div className="bubble-graph box-container padded">
        <h4 className='grey full-width'>{title}</h4>
        <div className="graph-content">
            <div className="bubbles-container">
            {nonZeroData.sort((a, b) => b.value - a.value).map((item, index) => {
              const size = Math.sqrt((item.value / totalValue) * 100) * 2.5;
              let left, top;
              
              if (index === 0) {
                left = 50;
                top = 50;
              } else if (index === 1) {
                left = 35;
                top = 35;
              } else if (index === 2) {
                left = 65;
                top = 55;
              }
              
              return (
                <div 
                  key={index} 
                  className="bubble" 
                  style={{
                    width: `${size*8}px`,
                    height: `${size*8}px`,
                    left: `${left}%`,
                    top: `${top}%`,
                    backgroundColor: bubbleColors[index % bubbleColors.length],
                  }}
                >
                  <span className="bubble-percentage">{Math.round((item.value / totalValue) * 100)}%</span>
                </div>
              );
            })}
            </div>
           
            </div>
            <div className="bubble-legend flex">
                {data.map((item, index) => (
                    <div key={index} className="legend-item">
                        <div className="legend-color" style={{ backgroundColor: bubbleColors[index % bubbleColors.length] }}></div>
                        <h5 className="legend-label">{item.label}</h5>
                        <span className="legend-value">{item.value} ({Math.round((item.value / totalValue) * 100)}%)</span>
                    </div>
                ))}
        </div>
    </div>
  );
};

export default BubbleGraph;
