class Cache{
    save = (key, value) => {
        localStorage.setItem(key, value);
    }

    get = (key) => {
        return localStorage.getItem(key);
    }

    saveObject = (key, obj) => {
        localStorage.setItem(key, JSON.stringify(obj));
    }
    
    getObject = (key) => {
        try{
            let obj = JSON.parse(localStorage.getItem(key));
            if(obj === null){
                obj = {};
                localStorage.setItem(key, JSON.stringify(obj));
            }
            return obj;
        }catch(err){
            localStorage.setItem(key, JSON.stringify({}));
            return {};
        }
    }

    deleteObject = (key) => {
        localStorage.removeItem(key);
    }

}

const CacheSingleton = new Cache();

export default CacheSingleton;