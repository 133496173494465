import './index.scss';
import { Component } from "react";
import { connect } from "react-redux";
import Breadcrumbs from '../Breadcrumbs'; // Import Breadcrumbs
import BrandContainer from '../BrandContainer';
import DatePicker from '../DatePicker';
class TopBar extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    
    render = () => {
        return (
            <div className='top-bar'>
                <div className='left-side'>
                    <Breadcrumbs /> {/* Add Breadcrumbs here */}
                    <DatePicker/>
                </div>
                <div className='right-side'>
                    <BrandContainer/>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
}

export default connect(mapStateToProps)(TopBar);