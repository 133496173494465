import React from 'react';
import './index.scss';
import { connect } from "react-redux";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { Link } from 'react-router-dom';


const Breadcrumbs = (props) => {
    const breadcrumbs = useBreadcrumbs();
    return (
        <div className='bread-container'>
            {breadcrumbs.map(({ breadcrumb }, index) => {
                if(index < 1){return}
                const isLast = index === breadcrumbs.length - 1;
                if(index === 1){
                    return (
                        <React.Fragment key={index}>
                            <h4 className='previous'>{breadcrumb}</h4>
                            {!isLast && <span className='separator'>/</span>}
                        </React.Fragment>
                    )
                }
                if(isLast){
                    return (
                        <React.Fragment key={index}>
                            <h4 className='current'>
                                {breadcrumb}
                                <span className='circle'></span>
                            </h4>
                        </React.Fragment>
                    )
                }
                return (
                    <React.Fragment key={index}>
                        <Link to={breadcrumb.key}>
                            <h4 className='link'>{breadcrumb}</h4>
                        </Link>
                        {!isLast && <span className='separator'> | </span>}
                    </React.Fragment>
                )
            })}
        </div>
    )
}

function mapStateToProps(state) {
    return {
        user :state.user
    };
  }

  export default connect(mapStateToProps)(Breadcrumbs);