import { Component } from 'react';
import './index.scss'
import { CloseOutlined } from '@mui/icons-material';
import Button from '../Button';


class Modal extends Component{
    constructor(props){
        super(props)
        this.state = {
            isOpen : false    
        }
    }

    open = () => {
        this.setState({isOpen : true});
        if(document.getElementById(`modal-${this.props.keyItem}`)){
            document.getElementById(`modal-${this.props.keyItem}`).classList.remove('hideModal');
        }
    }
    
    closeModal = () => {
        this.setState({isOpen : false});
        if(document.getElementById(`modal-${this.props.keyItem}`)){
            document.getElementById(`modal-${this.props.keyItem}`).classList.add("hideModal");
        }
    }

    renderMiddleModal = () => {
        const { keyItem, children, hasCloseButton=true } = this.props;

        return (
            <div id={`modal-${keyItem}`} key={`modal-${keyItem}`} className='hideModal'>
                <div className='background-modal'/>
			    <div className='modal-middle modal-box'>
                    {hasCloseButton ? <div className='closeIcon'>
                        <button onClick={() => this.closeModal()}>
                            <CloseOutlined/>
                        </button>
                    </div> : null} 
                    {children}
                </div>
            </div>
			
        )
    }

    render = () => {
        const { keyItem, children, middle, hasCloseButton=true } = this.props;
        if(middle){return this.renderMiddleModal()}
        else{
            return (
                <div id={`modal-${keyItem}`} key={`modal-${keyItem}`} className='modal-box hideModal'>
                    {children}
                    {hasCloseButton ? <Button className='button box-background' onClick={() => this.closeModal()}><h5>Close</h5></Button> : null}
                </div>
            )
        }
    }
    
}

export default Modal;
