
import React from 'react';
import { connect } from 'react-redux';
import { setModal } from '../../redux/actions/modal';
import store from '../../redux/store';
import './index.scss';

const BrandContainer = (props) => {
    const { app } = props;
    if(!app || !app.branding) return null;    
    const renderBrandBox = (app) => {
        return (
            <button onClick={() => store.dispatch(setModal('BrandPicker'))} className='hover-item brand-button'>
                <div>
                    <h4>{app.fullName}</h4>
                </div>
                <img src={app.branding.logo} alt={app.name}/>
            </button>
        );
    };
    

    return (
        <div className='brand-container'>
            {renderBrandBox(app)}
        </div>
    );
}

const mapStateToProps = state => {
    return {
        app: state.app,
    };
};

export default connect(mapStateToProps)(BrandContainer);