import React from 'react';
import { FaGithub, FaExternalLinkAlt, FaPlay, FaRocket } from 'react-icons/fa'; // Import additional icons
import './index.scss';
import Button from '../Button';
import { loadingGif } from '../../../config/assets/images';

const ApplicationInfo = ({ repoName, branch, appName, appVersion, appState, repoUrl, appUrl, onDeploy, onBuild, isDeploying, isBuilding }) => {
    return (
        <div className="application-info">
            <div className="repo-info">
                <FaGithub />
                <a href={repoUrl} target="_blank" rel="noopener noreferrer"><h5>{repoName}</h5></a>
                <span className="branch">{branch}</span>
            </div>
            <div className="actions">
                <Button className="fit button outline" onClick={() => window.open(appUrl, '_blank')}>
                   Open App  <FaExternalLinkAlt /> 
                </Button>
                <Button className="fit button build" onClick={onBuild} disabled={isBuilding}>
                    {isBuilding ? <img src={loadingGif} alt="Loading..." className="loading-gif" /> : <> <h5>Build</h5><FaPlay /></>}
                </Button>
                <Button className="fit button deploy" onClick={onDeploy} disabled={isDeploying}>
                    {isDeploying ? <img src={loadingGif} alt="Loading..." className="loading-gif" /> : <> <h5>Deploy</h5><FaRocket /></>}
                </Button>
            </div>
        </div>
    );
}

export default ApplicationInfo;