import './index.scss';
import { Component } from 'react';
import { connect } from 'react-redux';
import Footer from "../../../components/Footer";
import { loadingGif, logo } from '../../../../config/assets/images';
import InputText from '../../../components/InputText';
import GoogleSignup from '../../../components/GoogleSignup';
import UserAPI from '../../../lib/User';
import UrlNavigatorSingleton from '../../../lib/UrlNavigator';
import NotificationTab from '../../../components/NotificationTab';
import { isValidEmail, isValidPassword, isValidName, generateRandomString } from '../../../lib/Helpers';
import ChatWidget from '../../../components/ChatWidget';
import GoogleAnalyticsSingleton from '../../../lib/GoogleAnalytics';
import { MetaTags } from 'react-meta-tags';
import NotificationHandlerSingleton from '../../../lib/NotificationHandler';
import CommunityIcon from '../../../components/CommunityIcon';
import Button from '../../../components/Button';    

class SignupPage extends Component{
    constructor(props){super(props); this.state = {register : true, isReadyToSubmit : false, resetPassword : false}}

    componentDidMount = async () => {
        //GoogleAnalyticsSingleton.pageview('signup');
        await this.__goToApp();
    }

    __goToApp = async () => {
        this.setState({isLoading : true})
        // Verify if User Exists in Cache
        let isAuth = await UserAPI.auth();
        if(isAuth){
            // Go to Signup Page
            this.setState({isLoading : false})
            UrlNavigatorSingleton.toApp();
        }else{
            this.setState({isLoading : false})
        }
    }

    resetPassword = async () => {
        try{
            this.setState({ isLoading: true });
            let res = await UserAPI.requestResetPassword({
                email : this.state.email
            })

            if(res.status != 200){ throw new Error(res.message)}
            NotificationHandlerSingleton.onNotification({message : "An Email has been sent to restore your password", code : 200})
            this.setState({isLoading : false})
        }catch(err){
            //Handle Err with UI
            console.log(err);
            this.setState({error : err, isLoading : false})
        }
    }

    register = async () => {
        try{
            this.setState({ isLoading: true });
            let res = await UserAPI.register({
                name : this.state.name,
                password : this.state.password,
                email : this.state.email
            })

            if(res.status != 200){ throw new Error(res.message)}
            await GoogleAnalyticsSingleton.register({id : generateRandomString(5)});
            await this.__goToApp();
        }catch(err){
            //Handle Err with UI
            console.log(err);
            this.setState({error : err, isLoading : false})
        }
    }

    login = async () => {
        try{
            this.setState({ isLoading: true });
            let res = await UserAPI.login({
                email : this.state.email,
                password : this.state.password
            })

            if(res.status != 200){ throw new Error(res.message)}
            await this.__goToApp();
        }catch(err){
            //Handle Err with UI
            alert(err);
            this.setState({error : err, isLoading : false})
        }
    }

    changeView = () => {
        this.setState({register : !this.state.register})
    }

    changeResetPasswordView = () => {
        this.setState({resetPassword : !this.state.resetPassword, register : false})
    }

    onChange = (key, value) => {
        this.setState({[key] : value});
    }

    openForm = () => {
        this.setState({isLoading :false})
    }

    closeForm = () => {
        this.setState({isLoading :true})
    }

    renderLogin = () => {
        const {isLoading} = this.state;
        const isDisabled = isLoading || !this.isReadyToSubmitLogin();

        return (
            <>
                <MetaTags>
                    <title>Signup | Free</title>
                    <meta name="description" content="Sign up Today! Access an exclusive library" />
                    <meta property="og:title" content="Sign up" />
                </MetaTags>
                <InputText 
                    onChange={this.onChange} 
                    title={"E-mail"} placeholder={'Enter your email'} type={'email'}
                    isValid={isValidEmail(this.state['email'])}
                    error={'Email is not valid'}
                />
                <InputText 
                    onChange={this.onChange} 
                    isValid={isValidPassword(this.state['password'])}
                    error={'Should have more than 7 characters'}
                    title={"Password"} placeholder={'Enter your password'} type={'password'}
                />
                    <a style={{cursor : 'pointer'}} onClick={this.changeResetPasswordView}>
                        <h5 className='text-left text-grey'> Forgot your Password?</h5>
                    </a>
                <Button disabled={isDisabled} 
                    className={`button ${isDisabled ? "btn-disabled" : ""}`} 
                    style={{marginTop : 10, opacity : isLoading ? 0.7 : 1}} 
                    onClick={this.login}>
                    {isLoading ? <img src={loadingGif} className='loading-gif'/> : 
                    <h4>
                        Login
                    </h4>}
                </Button>
                <div className='goToLoginContainer'>
                    <a onClick={this.changeView}>
                        <h5 className='text-center text-primary'> New here? Create an Account</h5>
                    </a>
                </div>
            </>
        )
    }


    isReadyToSubmitRegister = () => {
        return (
            isValidName(this.state['name'])
            && isValidEmail(this.state['email'])
            && isValidPassword(this.state['password'])
        )
    }

    isReadyToSubmitLogin = () => {
        return (
            isValidEmail(this.state['email'])
            && isValidPassword(this.state['password'])
        )
    }

    renderForgotPassword = () => {
        const {isLoading} = this.state; 
        const isDisabled = isLoading || !isValidEmail(this.state['email']);
        return (
            <>
                <InputText 
                    onChange={this.onChange} 
                    isValid={isValidEmail(this.state['email'])}
                    error={'Email is not valid, should end like ...@gmail.com'}
                    title={"E-mail"} placeholder={'Enter your email'} type={'email'}
                />
                <Button disabled={isDisabled} 
                    className={`button ${isDisabled ? "btn-disabled" : ""}`} 
                    style={{marginTop : 10, opacity : isLoading ? 0.7 : 1}} 
                    onClick={this.resetPassword}>
                    {isLoading ? <img src={loadingGif} className='loading-gif'/> : 
                    <h4>
                        Reset Password
                    </h4>}
                </Button>
                <h5 className='text-center grey'  style={{marginTop : 10}}>An Email will be sent to restore your Password</h5>
                <div className='goToLoginContainer'>
                    <a onClick={this.changeResetPasswordView}>
                        <h5 className='text-center text-primary'> You know your password? Try to Login</h5>
                    </a>
                </div>
            </>
        )
        
    }

    renderRegister = () => {
        const {isLoading} = this.state; 
        const isDisabled = isLoading || !this.isReadyToSubmitRegister();
        return (
            <>
                <InputText 
                    onChange={this.onChange} 
                    type={'name'}
                    isValid={isValidName(this.state['name'])}
                    error={'Name is too Long or too Short'}
                    title={"Name"} placeholder={'Enter your name'}
                />
                <InputText 
                    onChange={this.onChange} 
                    isValid={isValidEmail(this.state['email'])}
                    error={'Email is not valid, should end like ...@gmail.com'}
                    title={"E-mail"} placeholder={'Enter your email'} type={'email'}
                />
                <InputText 
                    onChange={this.onChange} 
                    isValid={isValidPassword(this.state['password'])}
                    error={'Should have more than 7 characters'}
                    title={"Password"} placeholder={'Enter your password'} type={'password'}
                />
                <Button disabled={isDisabled} 
                    className={`button ${isDisabled ? "btn-disabled" : ""}`} 
                    style={{marginTop : 10, opacity : isLoading ? 0.7 : 1}} 
                    onClick={this.register}>
                    {isLoading ? <img src={loadingGif} className='loading-gif'/> : 
                    <h4>
                        Create Account
                    </h4>}
                </Button>
                <h5 className='text-center grey'  style={{marginTop : 10}}>No Credit Card Required</h5>
                <div className='goToLoginContainer'>
                    <a onClick={this.changeView}>
                        <h5 className='text-center text-primary'> Already Registered? Go to Login</h5>
                    </a>
                </div>
            </>
        )
    }

    render = () => {
        const {isLoading, register, resetPassword} = this.state;

        return (
            <>
                <ChatWidget/>
                <NotificationTab/>
                <div className='signup-grid'>
                    <div className='singup-container'>
                        <div className='outside-container'>
                            <div className='inside-container'>
                                <div className='text-container-sign'>
                                    <div className='signup-container-main'>
                                        <img src={logo} className='logo-signup'/>
                                    </div>
                                    {/*<div className='google-division'>
                                        <GoogleSignup goToApp={this.__goToApp} openForm={this.openForm} closeForm={this.closeForm}/>
                                    </div>*/}
                                    {
                                    resetPassword ? this.renderForgotPassword() :
                                    register ? this.renderRegister() : this.renderLogin()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            )
    }
    
}

function mapStateToProps(state) {
    return {
        user : state.user
    };
  }

  export default connect(mapStateToProps)(SignupPage);
