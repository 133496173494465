import APISingleton from "../../api";
import store  from '../store';

export const updateApp = (app) => ({
    type: 'UPDATE_APP',
    payload: app
});

export const deleteApp = async (appId) => {
    try {
        const response = await APISingleton.deleteApp({ id: appId });
        if (response.success) {
            store.dispatch({ type: 'DELETE_APP_SUCCESS', payload: appId });
            // Optionally, navigate away or update the UI
            window.location.href = '/';
        }
    } catch (error) {
        console.error('Error deleting app:', error);
        // Handle error (e.g., dispatch an error action)
    }
};