import APISingleton from "../../api";
import { generateRandomString } from "../../lib/Helpers";
import store from "../store";

export const SET_APP = 'SET_APP';

let isLoadingApp = false;

export function setApp(data) {
	return async (dispatch) => {
		/* To clean the app state */
		if(!data || !data.id) {dispatch({type: SET_APP, action: Object.assign({})}); return;}

		if (isLoadingApp) {
			console.log('setApp is already running, skipping concurrent execution');
			return;
		}
		
		isLoadingApp = true;
		
		try {
			const date = store.getState().date;
			const dataRange = {
				start: date.start,
				end: date.end
			};
				
			// Execute all API calls in parallel
			const [addOns, appData, sessions, users, usersSummary, builds, stripe] = await Promise.all([
				APISingleton.getAppAddons({ id: data.id }),
				APISingleton.getStatus({ id: data.id }),
				APISingleton.getSessions({ id: data.id, startDate: dataRange.start.toISOString(), endDate: dataRange.end.toISOString() }),
				APISingleton.getUsersInfo({ id: data.id }),
				APISingleton.getUsersSummary({ id: data.id, startDate: dataRange.start.toISOString(), endDate: dataRange.end.toISOString() }),
				APISingleton.getBuilds({ id: data.id, startDate: dataRange.start.toISOString(), endDate: dataRange.end.toISOString() }),
				APISingleton.getStripeData({ id: data.id, startDate: dataRange.start.toISOString(), endDate: dataRange.end.toISOString() })
			]);
			
			// Assign the results to the data object
			data.addons = addOns;
			data.status = appData;
			data.sessions = sessions;
			data.users = {
				general: users?.users,
				summary: usersSummary
			};
			data.builds = builds;
			data.stripe = stripe;
			data.loaded = true;
	  		data.changeId = generateRandomString();

			dispatch({
				type: SET_APP,
				action: Object.assign({}, data)
			});
		} finally {
			isLoadingApp = false;
		}
	};
}