import React, { useEffect, useState } from 'react';
import './index.scss';
import Stat from '../../../components/Stat';
import { connect } from 'react-redux';
import Graph from '../../../components/Graph';
import Table from '../../../components/Table';
import { formatName, formatDate, formatPrice } from '../../../lib/Helpers';
import ReactCountryFlag from "react-country-flag";
import { createAvatar } from '@dicebear/core';
import { notionists } from '@dicebear/collection';
import SkeletonContainer from '../../../components/SkeletonContainer';

const UsersPage = (props) => {
    
    const [users, setUsers] = useState([]);
    const [usersSummary, setUsersSummary] = useState([]);
    var { app } = props;
    
    useEffect(() => {
       if(app && app.id && app.users && app.users.general) {
            setUsers(app.users.general);
            setUsersSummary(app.users.summary);
       }
    }, [app.changeId]);
        
    if(!users || users.length === 0 || !usersSummary || !usersSummary.current || !usersSummary.previous) {
        return (
            <div className='page'>
                <SkeletonContainer count={7} type='list'/>
            </div>
        )
    }
    // Calculate percentage changes
    const calculatePercentageChange = (current, previous) => {
        if (previous === 0) return 100;
        const change = ((current - previous) / previous) * 100;
        // Handle Infinity and NaN cases
        if (!isFinite(change)) return 100;
        if (isNaN(change)) return 0;
        return change;
    };

    const percentageChanges = {
        totalUsers: calculatePercentageChange(usersSummary.current.users, usersSummary.previous.users),
        newUsers: calculatePercentageChange(usersSummary.current.new_users, usersSummary.previous.new_users),
        activeSubscribers: calculatePercentageChange(usersSummary.current.active_subscribers, usersSummary.previous.active_subscribers),
        activeUsers: calculatePercentageChange(usersSummary.current.active_users, usersSummary.previous.active_users)
    };
    
    // Prepare data for the graph from daily_stats
    const graphLabels = usersSummary.current.daily_stats.map(stat => stat.day);
    const activeUsersData = usersSummary.current.daily_stats.map(stat => stat.active_users);
    const registeredUsersData = usersSummary.current.daily_stats.map(stat => stat.registered_users);
    
    const generateAvatar = (email) => {
        const avatar = createAvatar(notionists, { seed: email });
        return avatar.toDataUri();
    }
    
    // Add this function after the generateAvatar function
    const isToday = (date) => {
        const today = new Date();
        const checkDate = new Date(date);
        return checkDate.toISOString().split('T')[0] === today.toISOString().split('T')[0];
    };

    return (
        <div className="page">
            {/* Insert your Database components here */}
            { /* 4 Boxes of information */}
            <div className='flex'>
                {/* 4 Variables Information mapping */}
                <Stat title='Users' tag={'Total'} value={usersSummary.total.users} comparison={`${percentageChanges.totalUsers.toFixed(0)}%`} isLoading={false} type='stat'/>
                <Stat title='New Users' tag={'Date'} value={usersSummary.current.new_users} comparison={`${percentageChanges.newUsers.toFixed(0)}%`} isLoading={false} type='stat'/>
                <Stat title='Subscribers' tag={'Date'} value={usersSummary.current.active_subscribers} comparison={`${percentageChanges.activeSubscribers.toFixed(0)}%`} isLoading={false} type='stat'/>
                <Stat title='Active Users' tag={'Date'} value={usersSummary.current.active_users} comparison={`${percentageChanges.activeUsers.toFixed(0)}%`} isLoading={false} type='stat'/>
                {/* Graph of each variable when picked */}
            </div>
            <div className='small-margin'></div>
            <div className='full-width'>
                <div className='user-graph'>
                    <Graph 
                        title="User Activity"
                        xAxis={graphLabels}
                        yAxis={{
                            label: 'Users',
                            data: [
                                { values: activeUsersData, style: 'solid', label: 'Active Users' },
                                { values: registeredUsersData, style: 'solid', label: 'Registered Users' }
                            ]
                        }}
                        cumulative={false}
                    />
                </div>
            </div>
            <div className='small-margin'></div>
            <div className='box-container scrollable-table'>
                <Table>
                    <thead>
                        <tr>
                            <th>Avatar</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Country</th>
                            <th>Register Timestamp</th>
                            <th>Last Payment</th>
                            <th>Subscription</th>
                            <th>Active User</th>
                        </tr>
                    </thead>
                    {users.map((user) => {
                        return <tr key={user.id} className={isToday(user.register_timestamp) ? 'registered-today' : ''}>
                            <td className='td-center'>
                                <img src={generateAvatar(user.email)} alt="Avatar" className='avatar' />
                            </td>
                            <td>{formatName(user.name)}</td>
                            <td>{user.email}</td>
                            <td className='td-center'>
                                <ReactCountryFlag
                                    countryCode={user.country}
                                    svg
                                    className='country-flag'
                                    title={user.country}
                                />
                            </td>
                            <td>{formatDate(user.register_timestamp)}</td>
                            {/* Show Last Payment Amount */}
                            <td className='td-center'><h5 className='tag-outlined'>{user.subscription ? formatPrice(user.subscription.price) : 'No Payment'}</h5></td>                         
                            {/* If subscription is active show green dot, if not show grey dot */}
                            <td className='td-center'><div className={`status-icon ${user.subscription ? user.subscription.status === 'active' ? 'active' : 'inactive' : 'no-subscription'}`}></div></td>
                            {/* Check if the user has logged in the last 30 days */}
                            <td className='td-center'>
                                <div className={`status-icon ${new Date(user.last_login) >= new Date(new Date().setDate(new Date().getDate() - 30)) ? 'active' : 'inactive'}`}></div>
                            </td>
                        </tr>
                    })}
                </Table>
            </div>
            
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        app: state.app
    }
}

export default connect(mapStateToProps)(UsersPage);
