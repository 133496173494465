/* Create React class for Footer */
import React from 'react';
import './index.scss';
import { Link } from 'react-router-dom';
import { logo } from '../../../config/assets/images';
import { SocialIcon } from 'react-social-icons'
import { GENERAL_INFO } from '../../../config';

export default class Footer extends React.Component {
    render() {
        return (
        <footer>
            <div className='margin'></div>
            <div className='box-container footer-content'>
                <div>
                    <Link to="/">
                        <img src={logo} alt="Logo" className='logo' />
                    </Link>
                </div>
                <div>
                    <div className='socials center'>
                        {GENERAL_INFO.socials.map( 
                            (social) => {
                                return <SocialIcon url={social} bgColor={'transparent'} fgColor={'var(--text-color)'}/>
                            }
                        )}
                    </div>
                </div>
            
            </div>
            <div className='small-margin'></div>
            <div className='box-container sub-footer'>
                <h5 className='grey'> © 2024, {GENERAL_INFO.name}</h5>
                <div className='links'>
                    <a href={`mailto:${GENERAL_INFO.email}`}><h5>Contact Us</h5></a>
                    <Link to="/refund-policy"> <h5>Refund Policy</h5></Link>
                    <Link to="/terms-and-services"><h5>Terms & Conditions</h5></Link>
                    <Link to="/privacy-policy"><h5>Privacy Policy</h5></Link>
                </div>
            </div>
        </footer>
        );
    }
}
