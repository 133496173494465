/* Create Component of Stat inside a Box like number of signed users, with comparison with last week or last day with a percentage */
import React from 'react';
import './index.scss';
import { getCSSVariableValue } from '../../lib/Helpers';
import SkeletonContainer from '../SkeletonContainer';

const Info = (props) => {
    const { title, value, isLoading } = props;


    return (
        <div className='info-container'>
            <h5 className='grey'>{title}</h5>
            <div className='value-container'>
                {isLoading ? <SkeletonContainer count={1}/> : 
                    <h4 className='value'>{value}</h4>}
            </div>
        </div>
    )
}

export default Info;