import React from 'react';
import './index.scss';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import BuildIcon from '@mui/icons-material/Build';

const BuildsInfo = ({ builds = [] }) => {
  const getStatusIcon = (status) => {
    switch (status.toLowerCase()) {
      case 'succeeded':
        return <CheckCircleIcon className="icon-build-succeeded" />;
      case 'failed':
        return <ErrorIcon className="icon-build-failed" />;
      default:
        return <BuildIcon className="icon-build" />;
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  };
  
  return (
    <div className="builds-info">
        <div className="builds-list">
            {builds.map((build, index) => (
                <div key={index} className="builds-item">
                    <span className={`builds-icon ${build.buildStatus.toLowerCase()}`}>
                        {getStatusIcon(build.buildStatus)}
                    </span>
                    <div className="builds-content">
                        <div className="builds-header">
                            <span className="builds-email">{build.initiator}</span>
                            <span className={`builds-status ${build.buildStatus.toLowerCase()}`}>
                                {build.buildStatus}
                            </span>
                        </div>
                        <div className="builds-details">
                            <span className="builds-time">{formatDate(build.startTime)}</span>
                            <span className="builds-version">{build.id}</span>
                            <span className="builds-actions right">
                                <a href={`#/builds/${build.id}`}>View build log</a>
                            </span>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </div>
  );
};

export default BuildsInfo;