import React, { useState, useEffect } from 'react';
import './index.scss';
import Info from '../../../components/Info';
import { connect } from 'react-redux';
import { fromDate } from '../../../lib/Helpers';
import APISingleton from '../../../api';
import TabsContainer from '../../../components/TabsContainer';
import ApplicationInfo from '../../../components/ApplicationInfo';
import BuildsInfo from '../../../components/BuildsInfo';
import DeploymentLogs from '../../../components/DeploymentLogs';
import DomainContainer from '../../../components/DomainContainer';
import { updateApp } from '../../../redux/actions/appActions'; // Make sure this import exists
import SkeletonContainer from '../../../components/SkeletonContainer';
import InputText from '../../../components/InputText';
import Button from '../../../components/Button';
import { Delete } from '@mui/icons-material';
import { sendNotification } from '../../../redux/actions/notification';
import store from '../../../redux/store';
import NotificationHandlerSingleton from '../../../lib/NotificationHandler';

const ApplicationPage = (props) => {
    const { app, dispatch } = props; // Add dispatch to props
    const [showLogs, setShowLogs] = useState(false);
    const [logs, setLogs] = useState('Waiting for logs...');
    const [isDeploying, setIsDeploying] = useState(false);
    const [isBuilding, setIsBuilding] = useState(false);
    const [builds, setBuilds] = useState([]);
    const [status, setStatus] = useState(null);
    const [customEnvs, setCustomEnvs] = useState([{ name: 'NEW_ENV_KEY', value: 'NEW_ENV_VALUE', type: 'backend or frontend', wasAddedNow: false }]);
    
    useEffect(() => {
        if (app && app.id) {
            if (app.status) {
                setStatus(app.status);
            }

            if (app.builds) {
                setBuilds(app.builds.builds);
            }

            if (app.addons) {
                /* if addon custom does not exist, add it */
                if (!app.addons.find(addon => addon.name === 'custom')) {
                    app.addons.push({ name: 'custom', envVariables: [] });
                }
                setCustomEnvs([...app.addons.find(addon => addon.name === 'custom').envVariables, ...customEnvs]);
            }
        
        }
    }, [app.id]);

    
    const triggerBuild = async () => {
        setIsBuilding(true);
        try {
            setShowLogs(true);
            APISingleton.triggerBuild(
                { appId: app.id },
                (message) => {
                    setLogs(message);
                    const parsedMessage = JSON.parse(message);
                    if (parsedMessage.message === "Build Started") {
                        const newBuild = {
                            id: parsedMessage.latestBuild.id,
                            projectName: app.name,
                            buildStatus: parsedMessage.latestBuild.buildStatus,
                            startTime: parsedMessage.latestBuild.startTime,
                            endTime: null,
                            phases: parsedMessage.latestBuild.phases || [],
                            initiator: parsedMessage.latestBuild.initiator || 'System',
                            resolvedSourceVersion: parsedMessage.latestBuild.resolvedSourceVersion || 'Unknown'
                        };
                        setBuilds((prevBuilds) => [newBuild, ...prevBuilds]);
                    }
                },
                (error) => console.error('WebSocket error:', error),
                () => setIsBuilding(false)
            );
        } catch (error) {
            console.error('Error building application:', error);
        } finally {
            setIsBuilding(false);
        }
    };

    const triggerDeployment = async () => {
        setIsDeploying(true);
        try {
            setShowLogs(true);
            APISingleton.triggerDeployment(
                { appId: app.id },
                (message) => {
                    setLogs(message);
                    const parsedMessage = JSON.parse(message);
                    if (parsedMessage.message === "Deployment Started") {
                        const newBuild = {
                            id: parsedMessage.buildId,
                            projectName: app.name,
                            buildStatus: 'IN_PROGRESS',
                            startTime: new Date().toISOString(),
                            endTime: null,
                            phases: []
                        };
                        setBuilds((prevBuilds) => [newBuild, ...prevBuilds]);
                    }
                },
                (error) => console.error('WebSocket error:', error),
                () => setIsDeploying(false)
            );
        } catch (error) {
            console.error('Error deploying application:', error);
        } finally {
            setIsDeploying(false);
        }
    };

    const handleRefreshACMStatus = async () => {
        try {
            const response = await APISingleton.refreshACMStatus({ id: app.id });
        } catch (error) {
            console.error('Error refreshing ACM status:', error);
        }
    };

    const handleConfigureSSL = async (domainId) => {
        try {
            await APISingleton.configureSSL({ id: app.id, domainId });
        } catch (error) {
            console.error('Error configuring SSL:', error);
        }
    };

    const handleAddDomain = async (newDomain) => {
        try {
            const response = await APISingleton.addDomain({ domain: newDomain, id: app.id });
            if (response.success) {
                dispatch(updateApp({ ...app, domains: response.data.domains })); // Use updateApp action
            }
        } catch (error) {
            console.error('Error adding domain:', error);
        }
    };

    const handleDeleteDomain = async (domainId) => {
        try {
            const response = await APISingleton.deleteDomain({ id: app.id, domainId });
            if (response.success) {
                dispatch(updateApp({ ...app, domains: response.data.domains })); // Use updateApp action
            }
        } catch (error) {
            console.error('Error deleting domain:', error);
        }
    };
    
    const handleAddNewEnv = (env) => {
        /* If type is not backend or frontend, set it to backend */
        if (env.type !== 'backend' && env.type !== 'frontend') {
            NotificationHandlerSingleton.onNotification({
                type: 'Error',
                message: 'Type must be either "backend" or "frontend"'
            });
            return;
        }
        setCustomEnvs(prevState => {
            const newState = prevState.map(e => 
                e.name === env.name ? { ...e, wasAddedNow: true } : e
            );
            // Add a new empty env variable template at the end
            return [...newState, { name: 'NEW_ENV_KEY', value: 'NEW_ENV_VALUE', type: 'backend', wasAddedNow: false }];
        });
    }
    
    const handleRemoveEnv = (env) => {
        /* Should remove only the specific env by matching both name and value */
        setCustomEnvs(customEnvs.filter(e => 
            !(e.name === env.name && e.value === env.value && e.type === env.type)
        ));
    }
    
    const handleChangeNewEnv = (key, value, index) => {
        /* Change the value of the env */
        setCustomEnvs(prevState => {
            const newState = [...prevState];
            newState[index] = {
                ...newState[index],
                [key]: value
            };
            return newState;
        });
    };
    
    const handleSubmitNewEnv = async () => {
        try {
            if (!customEnvs) {
                return;
            }
            
            // Validate input
            const hasEmptyFields = customEnvs.some(env => 
                !env.name?.trim() || !env.value?.trim() || !env.type?.trim()
            );
            
            if (hasEmptyFields) {
                return;
            }

            /* Filter if not with flag wasAddedNow */
            const filteredCustomEnvs = customEnvs.filter(env => env.wasAddedNow);
            
            // Transform input to match API format
            const formattedEnvVariables = filteredCustomEnvs.map(input => ({
                name: input.name.trim(),
                value: input.value.trim(),
                type: input.type.trim()
            }));
            
            console.log(formattedEnvVariables);
            
            const response = await APISingleton.updateAddonEnvVariables({
                id: app.id,
                name: 'custom',
                envVariables: formattedEnvVariables
            });

            if (response.status === 200) {            
                // Reset input fields
            }
        } catch (error) {
            console.error('Error submitting env variables:', error);
        }
    };
    
    
    if (!status || !builds || builds.length === 0 || !app.id) {
        return (
            <div className='page'>
                <SkeletonContainer count={7} type='list' />
            </div>
        )
    }
    
    const renderEnvVariable = (env, index, isExisting = false) => {
        return (
            <div className='flex'>
                <div className='flex'>
                    <div className='flex-1'>
                        <InputText
                            type="name"
                            title="Name"
                            placeholder={env.name || "Enter key"}
                            value={env.name || ""}
                            onChange={(key, value) => handleChangeNewEnv(key, value, index)}
                            isDisabled={isExisting}
                        />
                    </div>
                    <div className='flex-1'>
                        <InputText
                            type="value"
                            title="Value"
                            placeholder={env.value || "Enter value"}
                            value={env.value}
                            onChange={(key, value) => handleChangeNewEnv(key, value, index)}
                            isDisabled={isExisting}
                        />
                    </div>
                    <div className='flex-1'>
                        <InputText
                            type="type"
                            title="Type"
                            placeholder={env.type || "backend/frontend"}
                            value={env.type}
                            onChange={(key, value) => handleChangeNewEnv(key, value, index)}
                            isDisabled={isExisting}
                        />
                    </div>
                    {isExisting ? null : (
                        (
                            <Button className='button fit right primary' onClick={() => handleAddNewEnv(env)}>
                                <h4>Add</h4>
                            </Button>
                        )
                    )}
                    
                    {isExisting && (
                        <Button className='button fit right icon-button primary' onClick={() => handleRemoveEnv(env)}>
                            <Delete />
                        </Button>
                    )}
                </div>
            </div>
        )
    }
    
    const tabs = [
        {
            key: 'general',
            label: 'General',
            content: (
                <div className='margin'>
                    <h4 className='grey'>General</h4>
                    <div className='box-container space flex'>
                        <Info title='Name' value={app.name} />
                        <Info title='Id' value={app.id} />
                        <Info title='Owner Id' value={app.owner} />
                    </div>
                </div>
            )
        },
        {
            key: 'hosting',
            label: 'Hosting',
            content: (
                <div className='margin'>
                    <h4 className='grey'>Hosting</h4>
                    <div className='box-container space flex'>
                        <Info title='Application State' value={app.hosting.active ? 'Running' : 'Not Running'} />
                        <Info title='Internal Id' value={app.hosting.external_id} />
                        <Info title='Last Update' value={fromDate(app.hosting.updatedAt)} />
                    </div>
                </div>
            )
        },
        {
            key: 'domain',
            label: 'Domain',
            content: (
                <div className='margin'>
                    <h4 className='grey'>Domain</h4>
                    <DomainContainer
                        domains={app.domains}
                        onAddDomain={handleAddDomain}
                        onRefreshACMStatus={handleRefreshACMStatus}
                        onConfigureSSL={handleConfigureSSL}
                        onDeleteDomain={handleDeleteDomain}
                    />
                </div>
            )
        },
        {
            key: 'env-variables',
            label: 'Environment Variables',
            content: (
                <div className='margin'>
                    <h4 className='grey'>Environment Variables</h4>
                    <div className='margin'></div>
                    <div className='box-container padded'>
                        {customEnvs.map((env, index) => {
                            return renderEnvVariable(env, index,  env.wasAddedNow == false ? false : true);
                        })}
                        <div className='margin'></div>
                        {/* Button to add new env variable */}
                        <div className='small-margin'></div>
                        <div className='divider'></div>
                        <div className='margin'></div>
                        <div className='flex fifty'>
                            <div className='flex-1'></div>
                            <Button className='button fit right' onClick={handleSubmitNewEnv}>
                                <h4>Submit</h4>
                            </Button>
                        </div>
                    </div>
                </div>
            )
        },
        {
            key: 'code',
            label: 'Code',
            content: (
                <div className='flex'>
                    <h4 className='grey'>Code</h4>
                    <div className='box-container space flex'>
                        <Info title='Repository Name' value={app.hosting.repository_name} />
                        <Info title='Repository URL' value={app.hosting.repository_url} />
                    </div>
                </div>
            )
        },
        {
            key: 'builds',
            label: 'Builds',
            content: (
                <div className='margin builds-container'>
                    <h4 className='grey'>Builds</h4>
                    <BuildsInfo builds={builds} />
                </div>
            )
        }
    ];

    return (
        <div className="page">
            <div className='small-margin'></div>
            <ApplicationInfo
                repoName={app.hosting.repository_name}
                branch="main"
                appName={app.name}
                appVersion={app.version}
                appState={app.hosting.active ? 'Running' : 'Not Running'}
                repoUrl={app.hosting.repository_url}
                appUrl={`https://${app.hosting.alias_domain}`}
                onBuild={triggerBuild}
                onDeploy={triggerDeployment}
                isBuilding={isBuilding}
                isDeploying={isDeploying}
            />
            <div className='small-margin'></div>
            <div className="main-content">
                <TabsContainer tabs={tabs} />
                {showLogs && <DeploymentLogs className="deployment-logs" logs={logs} />}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    app: state.app
});

export default connect(mapStateToProps)(ApplicationPage);