import { Component } from 'react';
import './index.scss';


export default class Button extends Component {
    constructor(props) {
        super(props);
        this.state = { isLoading: false };
    }

    handleClick = async () => {
        this.setState({ isLoading: true });
        if (this.props.onClick) {
            await this.props.onClick();
        }
        this.setState({ isLoading: false });
    }

    componentWillUnmount() {
        this.setState({ isLoading: false });
    }
    
    render = () => {
        return (
            <button disabled={this.state.isLoading} {...this.props} onClick={this.handleClick}>
                {this.state.isLoading ? <div className="loading-circle"></div> : this.props.children}
            </button>
        );
    }
}
    