/* Create Table Component */
import React from 'react';
import './index.scss';

const Table = (props) => {
    const { children, title } = props;
    
    return (
        <div className='table-container'>
            {title && <h4 className='grey small-margin'>{title}</h4>}
            <table className='table'>
                {children}
            </table>
        </div>
    )
}

export default Table;
