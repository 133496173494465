import './index.scss';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Rating } from 'react-simple-star-rating'

class CommunityIcon extends Component{
    constructor(props){super(props)}


    render = () => {
        return (
            <div>
             
            </div>
        )
    }
    
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }

  export default connect(mapStateToProps)(CommunityIcon);
