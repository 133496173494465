/* Create a general modal component that can be used to display any type of modal */

import React from 'react';
import './index.scss';
import Modal from '../index';

const ModalGeneral = ({ title, message, children }) => {
    return (
        <Modal keyItem='ModalGeneral' middle={true}>
            <div className="modal-general">
                <div className="modal-header">
                    <h3>{title}</h3>
                </div>
                <div className="modal-body">
                    <p>{message}</p>
                    {children}
                </div>
            </div>
        </Modal>
    );
};

export default ModalGeneral;

