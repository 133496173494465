import React from "react";
import { connect } from "react-redux";
import Footer from "../../../components/Footer";
import InputText from "../../../components/InputText";
import NotificationTab from "../../../components/NotificationTab";
import ChatWidget from "../../../components/ChatWidget";
import { isValidPassword } from "../../../lib/Helpers";
import { loadingGif, logo } from '../../../../config/assets/images';
import './index.scss';
import UserAPI from "../../../lib/User";
import UrlNavigatorSingleton from "../../../lib/UrlNavigator";
import { MetaTags } from "react-meta-tags";
import NotificationHandlerSingleton from "../../../lib/NotificationHandler";


class ResetPasswordPage extends React.Component{
    constructor(props){super(props); this.state = {isLoading : false, password : ''};}

 
    onChange = (key, value) => {
        this.setState({[key] : value});
    }

    resetPassword = async () => {
        const {password} = this.state;
        this.setState({isLoading : true});
        const { email, token } = UrlNavigatorSingleton.getFields(["email", "token"]);

        let res = await UserAPI.resetPassword({
            password : this.state.password,
            token, email
        });
        if(res.status == 200){ 
            /* Inform on Modal */
            NotificationHandlerSingleton.onNotification({message : "Password Reset Successfully", status : "success"})
            /* Go to Login */
            setTimeout( () => UrlNavigatorSingleton.toApp(), 1000)
            
        }

        this.setState({isLoading : false})
       
    }

    render = () => {
        const {isLoading} = this.state;
        const isDisabled = !isValidPassword(this.state['password']);

        return (
            <> 
                <MetaTags>
                    <title>Reset Password</title>
                    <meta name="description" content="Reset your Password" />
                    <meta property="og:title" content="Reset" />
                </MetaTags>
                <div>
                    <ChatWidget/>
                    <NotificationTab/>
                     <div className='singup-container'>
                        <div className='outside-container'>
                            <div className='inside-container'>
                                <div className='text-container-sign'>
                                    <div className='signup-container-main'>
                                        <img src={logo} className='logo-signup'/>
                                        <h2 className='text-center'>Reset your Password</h2>
                                        <h4 className='text-center text-grey'>Set your new Password</h4>
                                    </div>
                                    <InputText 
                                        onChange={this.onChange} 
                                        isValid={isValidPassword(this.state['password'])}
                                        error={'Should have more than 7 characters'}
                                        title={"New Password"} placeholder={'Enter your new password'} type={'password'}
                                    />
                                    <button disabled={isDisabled} 
                                        className={`btn btn-primary full-width btn-sub ${isDisabled ? "btn-disabled" : ""}`} 
                                        style={{marginTop : 30, opacity : isLoading ? 0.7 : 1}} 
                                        onClick={this.resetPassword}>
                                        {isLoading ? <img src={loadingGif} className='loading-gif'/> : 
                                        <h4>
                                            Reset Password 
                                        </h4>}
                                    </button>
                                </div>      
                            </div>      
                        </div>
                    </div>
                    <Footer/>
                </div>
            </>
        );
    
    }
}



function mapStateToProps(state) {
    return {
        user : state.user
    };
  }

  export default connect(mapStateToProps)(ResetPasswordPage);
