import { Component } from 'react';
import { connect } from 'react-redux';
import './index.scss';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import UserAPI from '../../lib/User';
import { GOOGLE_CLIENT_ID } from '../../../config';
import GoogleAnalyticsSingleton from '../../lib/GoogleAnalytics';


class GoogleSignup extends Component{
    constructor(props){super(props)}

    responseGoogle = async (res) => {
        if(res && (res.error == 'popup_closed_by_user')){
            this.props.openForm();
        }
        if(res.credential){
            res = await UserAPI.googleLogin({googleBearerToken : res.credential});
            let register_timestamp = UserAPI.user.register_timestamp;   
            // If Register timestamp is less than 1 min, then it is a new user
            if(register_timestamp && (Date.now() - new Date(register_timestamp)) < 60000){
                GoogleAnalyticsSingleton.register({_id : res.id});
            }else{
                // Old User
            }
            await this.props.goToApp();
        }
    };

    onRequest = (e) => {
        this.props.closeForm();
    }

    render = () => {

        return (
            <div className='google-container'>
                <GoogleOAuthProvider 
                    clientId={GOOGLE_CLIENT_ID}>
                   <GoogleLogin
                        width={'100%'}
                        className='google-button text-center text-black'
                        onSuccess={this.responseGoogle}
                        onError={this.responseGoogle}
                        />
                    </GoogleOAuthProvider>
            </div>
        )
    }
    
}

function mapStateToProps(state) {
    const user = state.user;
    return {
        user
    };
  }

  export default connect(mapStateToProps)(GoogleSignup);
