import React from 'react';
import { logo } from '../../../config/assets/images';
import Button from '../Button';
import './index.scss';
import { useNavigate } from 'react-router-dom';


const Header = ({showRegister=true}) => {
  const navigate = useNavigate();
  
  return (
    <header>
        <div className='left'>
        </div>
        <img src={logo} alt='logo' className='logo'/>
        <div className='right'>
            {showRegister ? <Button className={'fit'} onClick={() => navigate("/conversion")}> <p>Subscribe</p> </Button> : null}  
        </div>
    </header>
  );
};

export default Header;
