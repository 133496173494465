import React, { useState } from 'react';
import './index.scss';

const TabsContainer = ({ tabs }) => {
    const [activeTab, setActiveTab] = useState(tabs[0].key);

    return (
        <div className="tabs-container">
            {/* Tabs */}
            <div className="tabs">
                {tabs.map(tab => (
                    <div
                        key={tab.key}
                        className={`tab ${activeTab === tab.key ? 'active' : ''}`}
                        onClick={() => setActiveTab(tab.key)}
                    >
                        {tab.label}
                    </div>
                ))}
            </div>
            {/* Tab Content */}
            <div className="tab-content">
                {tabs.map(tab => (
                    activeTab === tab.key && (
                        <div key={tab.key} className="tab-pane">
                            {tab.content}
                        </div>
                    )
                ))}
            </div>
        </div>
    );
};

export default TabsContainer;