import React, { useState } from 'react';
import './index.scss';
import Button from '../Button';
import { FaCheckCircle, FaPencilAlt, FaTimes, FaClock, FaCheck, FaQuestion, FaTrash } from 'react-icons/fa';
import ConfirmationModal from '../ConfirmationModal'; // We'll create this component next
import Modal from '../Modal'; // We'll create this component next
import ModalGeneral from '../Modal/ModalGeneral';
import store from '../../redux/store';
import { setModal } from '../../redux/actions/modal';
import config from '../../config'; // Assume we have a config file
import InputText from '../InputText';
import Table from '../Table';
const DomainContainer = ({
	domains = [],
	onAddDomain,
	onRefreshACMStatus,
	onDeleteDomain,
	appUrl = config.DEFAULT_APP_URL // Use a default from config
}) => {
const [isSidebarOpen, setIsSidebarOpen] = useState(false);
const [newDomain, setNewDomain] = useState('');
const [domainToDelete, setDomainToDelete] = useState(null);
const [selectedDomain, setSelectedDomain] = useState(null);

const openSidebar = () => setIsSidebarOpen(true);
const closeSidebar = () => setIsSidebarOpen(false);

const handleAddDomain = async () => {
	if (newDomain) {
		try {
			await onAddDomain(newDomain);
			closeSidebar();
			setNewDomain('');
			// Optionally, refresh the domain list here
		} catch (error) {
			console.error('Error adding domain:', error);
			// Handle error (e.g., show error message)
		}
	}
};

const getStatusIcon = (status) => {
	switch (status.toLowerCase()) {
		case 'pending':
		case 'pending_validation':
			return <FaClock className="status-icon pending" title={status} />;
		case 'issued':
		case 'active':
			return <FaCheck className="status-icon success" title={status} />;
		case 'failed':
		case 'inactive':
			return <FaTimes className="status-icon error" title={status} />;
		default:
			return <FaQuestion className="status-icon unknown" title={status} />;
	}
};

const handleDeleteClick = (domain) => {
	setDomainToDelete(domain);
};

const handleConfirmDelete = () => {
	if (domainToDelete) {
		onDeleteDomain(domainToDelete.id);
		setDomainToDelete(null);
	}
};

const handleCancelDelete = () => {
	setDomainToDelete(null);
};

const handleCloseModal = () => {
	setSelectedDomain(null);
};

const openModal = () => {
	store.dispatch(setModal('ModalGeneral'))
};

return (
	<div className="domain-container">
		<div className="ssl-section">
			<h2>SSL Certificates</h2>
			<p>
				SSL Certificates provide end-to-end encryption and integrity for all web requests
				to ensure information is transmitted securely.
			</p>
			<div className="ssl-info">
				<div className="ssl-status">
					<h3>SSL Certificates</h3>
				</div>
				<div className="ssl-message">
					<FaCheckCircle className="icon-check" />
					<p>Your certificate is automatically managed.</p>
				</div>
			</div>
		</div>
		<div className="domain-section">
			<h2>Domains</h2>
			<p>
				You can add custom domains to your app, then visit <a onClick={openModal} href="#">Configuring DNS</a> to setup your
				DNS target.
			</p>
			<div className="domain-controls">
				<Button className="refresh-acm" onClick={onRefreshACMStatus}>
					Refresh ACM Status
				</Button>
				<Button className="add-domain" onClick={openSidebar}>
					Add domain
				</Button>
			</div>
			<table className="domain-table">
				<Table>
					<thead>	
						<tr>
							<th>Domain Name</th>
							<th>DNS Target</th>
							<th>CNAME Name</th>
							<th>CNAME Value</th>
							<th>ACM Status</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{domains.length > 0 ? (
							domains.map((domain) => (
								<tr key={domain.id}>
									<td>{domain.url}</td>
									<td>{domain.dnsTarget || 'Not set'}</td>
									<td>{domain.cNameName || 'Not set'}</td>
									<td>{domain.cNameValue || 'Not set'}</td>	
									<td>{getStatusIcon(domain.acmStatus)}</td>
									<td>
										<FaTrash
											className="delete-icon"
											onClick={() => handleDeleteClick(domain)}
											title="Delete Domain"
										/>
									</td>
								</tr>
							))
						) : (
							<tr>
								<td colSpan="6">No domains added yet.</td>
							</tr>
						)}
					</tbody>
				</Table>
			</table>
			{domainToDelete && (
				<ConfirmationModal
					message={`Are you sure you want to delete the domain "${domainToDelete.url}"?`}
					onConfirm={handleConfirmDelete}
					onCancel={handleCancelDelete}
				/>
			)}
			<ModalGeneral
				title="DNS Setup Help"
				message="Update your DNS records to point to the following CNAME records"
			>
				<div>
					<Table>
						<thead>
							<tr>
								<th>Type</th>
								<th>Name</th>
								<th>Value</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>CNAME</td>
								<td>www (or @ for root domain)</td>
								<td>{domains[0]?.dnsTarget || 'Not set'}</td>
							</tr>
							<tr>
								<td>CNAME</td>
								<td>{domains[0]?.cNameName || 'Not set'}</td>
								<td>{domains[0]?.cNameValue || 'Not set'}</td>
							</tr>
							<tr>
								<td>CA</td>
								<td>@</td>
								<td>amazon.com</td>
							</tr>
						</tbody>
					</Table>
					<div className='small-margin'></div>
					<p>Click Refresh ACM Status to update the status of your certificate.</p>
					<p>Ensure to remove any existing A records pointing to another domain.</p>
				</div>
			</ModalGeneral>
		</div>
		<div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
			<div className="sidebar-content">
				<div className="sidebar-header">
					<h2>New Domain</h2>
					<button className="close-button" onClick={closeSidebar}>
						<FaTimes />
					</button>
				</div>
				<div className="sidebar-body">
					<InputText
						type={'domain'}
						onChange={(type, text) => setNewDomain(text)}
						isDisabled={false}
						title={"Domain Name"}
						value={newDomain}
						placeholder={"yourdomain.com"}
					/>
					<p className="info-text">
						See the <a href="#" className="link">Rules on adding domains</a> section if you receive the
						error message "example.com is currently in use by another app".
					</p>
				</div>
				<div className="sidebar-footer">
					<Button onClick={closeSidebar}>Cancel</Button>
					<Button className="primary fit" onClick={handleAddDomain}>Next</Button>
				</div>
			</div>
		</div>
	</div>
);
};


export default DomainContainer;
