
import React from 'react';
import './index.scss';

const EmailPage = () => {
    return (
        <div className="page">
            {/* Insert your Email components here */}
        </div>
    );
}

export default EmailPage;
