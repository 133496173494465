import { useState } from "react";
import InputText from "../../../../components/InputText";

const CreateName = (props) => {

    const onChange = (type, text) => {
        props.set({type, data : text});
    }

    return (
        <div>
            <InputText 
                type={'name'} 
                onChange={onChange} 
                placeholder="Your Application Name" 
            />
        </div>
    )
}



export default CreateName;
